import React from 'react';
import { useTranslation } from 'react-i18next';
import vector from '../assets/ImagesVehicle/person.png';

const MessageItem = ({ name, lastMessage, time, unreadCount }) => (
  <div className="bg-white rounded-xl shadow-md p-4 mb-4 transition-all duration-300 hover:shadow-lg">
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3 rtl:space-x-reverse">
        <img className="w-12 h-12 rounded-full" src={vector} alt={name} />
        <div className="flex-grow">
          <h3 className="font-bold text-lg">{name}</h3>
          <p className="text-gray-600 truncate">{lastMessage}</p>
        </div>
      </div>
      <div className="text-right">
        <p className="text-sm text-gray-500">{time}</p>
        {unreadCount > 0 && (
          <span className="inline-block bg-green-500 text-white text-xs font-bold rounded-full px-2 py-1 mt-1">
            {unreadCount}
          </span>
        )}
      </div>
    </div>
  </div>
);

const Messages = () => {
  const { t } = useTranslation();

  const messages = [
    { id: 1, name: 'محمد خليل', lastMessage: 'مرحبا، كيف حالك؟', time: '10:45', unreadCount: 3 },
    { id: 2, name: 'فاطمة أحمد', lastMessage: 'شكرا لك على المساعدة', time: '09:30', unreadCount: 1 },
    { id: 3, name: 'علي حسن', lastMessage: 'هل يمكننا تحديد موعد للقاء؟', time: 'أمس', unreadCount: 0 },
    { id: 4, name: 'زينب محمود', lastMessage: 'أرسلت لك الملفات المطلوبة', time: 'الأحد', unreadCount: 2 },
    { id: 5, name: 'عمر سعيد', lastMessage: 'سأكون متأخرا قليلا', time: 'السبت', unreadCount: 0 },
  ];

  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">{t('Messages')}</h1>
        
        <div className="space-y-4">
          {messages.map((message) => (
            <MessageItem
              key={message.id}
              name={message.name}
              lastMessage={message.lastMessage}
              time={message.time}
              unreadCount={message.unreadCount}
            />
          ))}
        </div>

        {messages.length === 0 && (
          <p className="text-center text-gray-500 mt-8">
            {t('no_messages')}
          </p>
        )}
      </div>
    </div>
  );
};

export default Messages;