import React from "react";
import Images from "../../assets/ImagesVehicle/person.png";
// import { Link } from "react-router-dom";

const DescriptionVehicleBroker = () => {
  return (
    <div className="container mx-auto p-[80px] rounded-lg">
      <p className="text-2xl text-gray-800 text-right font-medium space-x-4 tracking-wide leading-loose">
        تعتمد سيارة 2023 Lamborghini Urus على محرك V8 سعة 4.0 لتر مع شاحن
        توربيني مزدوج ينتج قوة 650 حصانًا وعزم دوران يبلغ 850 نيوتن متر. يتصل
        هذا المحرك بناقل حركة أوتوماتيكي من ثماني سرعات. تتسارع السيارة من 0 إلى
        100 كم/ساعة في 3.6 ثانية. وتبلغ سرعتها القصوى 305 كم/ساعة. تعتمد سيارة
        2023 Lamborghini Urus على محرك V8 سعة 4.0 لتر مع شاحن توربيني مزدوج ينتج
        قوة 650 حصانًا وعزم دوران يبلغ 850 نيوتن متر. يتصل هذا المحرك بناقل حركة
        أوتوماتيكي من ثماني سرعات. تتسارع السيارة من 0 إلى 100 كم/ساعة في 3.6
        ثانية. وتبلغ سرعتها القصوى 305 كم/ساعة. تعتمد سيارة 2023 Lamborghini
        Urus على محرك V8 سعة 4.0 لتر مع شاحن توربيني مزدوج ينتج قوة 650 حصانًا
        وعزم دوران يبلغ 850 نيوتن متر. يتصل هذا المحرك بناقل حركة أوتوماتيكي من
        ثماني سرعات. تتسارع السيارة من 0 إلى 100 كم/ساعة في 3.6 ثانية. وتبلغ
        سرعتها القصوى 305 كم/ساعة.
      </p>
      <div className="w-screen ml-[-145px] rounded-lg">
        <img
          src={"path-to-map-image.jpg"}
          alt="Map"
          className="w-full h-64 object-cover rounded-md mb-4"
        />
        <div className="flex items-center p-6">
          <img
            src={Images}
            alt="Person"
            className="w-12 h-12 rounded-full mr-4"
          />
          <div>
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              className="ml-[165px]"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.862 26.9962C17.9147 26.9245 12.3961 26.162 6.61639 20.3848C0.838069 14.6064 0.0767314 9.09016 0.00370796 7.14191C-0.104475 4.1729 2.17007 1.28906 4.79756 0.162841C5.11397 0.0262438 5.46045 -0.0257631 5.80303 0.0119242C6.1456 0.0496114 6.47249 0.175698 6.75161 0.377811C8.91527 1.95425 10.4082 4.3392 11.6902 6.21443C11.9722 6.62643 12.0928 7.12778 12.029 7.62295C11.9651 8.11812 11.7213 8.57251 11.344 8.89952L8.70567 10.8586C8.5782 10.9506 8.48848 11.0858 8.45316 11.2389C8.41785 11.3921 8.43934 11.5529 8.51364 11.6914C9.11135 12.7771 10.1743 14.3941 11.3913 15.6109C12.6097 16.8277 14.3028 17.9607 15.4644 18.6259C15.61 18.7076 15.7815 18.7305 15.9434 18.6897C16.1054 18.649 16.2456 18.5478 16.3353 18.4068L18.0527 15.7934C18.3684 15.3741 18.8342 15.0932 19.3525 15.0095C19.8707 14.9258 20.4013 15.0459 20.833 15.3445C22.7356 16.6614 24.9561 18.1283 26.5815 20.2091C26.8001 20.4902 26.9391 20.8248 26.9841 21.178C27.0291 21.5312 26.9783 21.8899 26.8371 22.2168C25.7053 24.8573 22.8411 27.1057 19.862 26.9962Z"
                fill="#800080"
              />
            </svg>
            <p className="text-gray-600 mt-[-25px]">+966 123 456 789</p>
          </div>
          <div className="ml-auto">
          </div>
          <p className="text-2xl font-bold">للتواصل</p>
        </div>
        <div className="text-center w-full bg-white">
          <span className="mr-[-110px] font-medium">
            السعر الصافي{" "}
            <span className="text-2xl">
              <span className="text-blue-600 text-bold text-2xl">
                200.000 ريال
              </span>
            </span>
          </span>
          <a href="#1" to="/choose_categories">
          <button className=" ml-[130px]">
          <span className="float-end ml-4 text-lg font-semibold mt-[7px]">رفض</span>
            <svg
              width="45"
              height="45"
              viewBox="0 0 83 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M82.4165 41.6067C82.4165 64.3652 63.9668 82.815 41.2083 82.815C18.4496 82.815 0 64.3652 0 41.6067C0 18.848 18.4496 0.398438 41.2083 0.398438C63.9668 0.398438 82.4165 18.848 82.4165 41.6067ZM28.7206 29.1192C29.9276 27.9122 31.8844 27.9122 33.0915 29.1192L41.2083 37.2357L49.3246 29.1192C50.5316 27.9123 52.4886 27.9123 53.6956 29.1192C54.9026 30.3262 54.9026 32.2831 53.6956 33.4899L45.5788 41.6067L53.6956 49.7231C54.9026 50.9301 54.9026 52.8871 53.6956 54.094C52.4886 55.301 50.5316 55.301 49.3246 54.094L41.2083 45.9777L33.0915 54.094C31.8846 55.301 29.9277 55.301 28.7207 54.094C27.5138 52.8871 27.5138 50.9301 28.7207 49.7235L36.8373 41.6067L28.7206 33.4899C27.5136 32.283 27.5136 30.3261 28.7206 29.1192Z"
                fill="#990033"
              />
            </svg>
          </button>
          <button className="ml-[130px] p-8">
          <span className="float-end ml-4 text-lg font-semibold mt-[7px]">قبول</span>
            <svg
              width="45"
              height="45"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 30.7031C12.0333 30.7031 9.13319 29.8234 6.66646 28.1752C4.19972 26.527 2.27714 24.1843 1.14183 21.4434C0.00651479 18.7025 -0.290556 15.6865 0.288222 12.7768C0.867 9.86706 2.29563 7.19432 4.39342 5.09653C6.4912 2.99875 9.16394 1.57013 12.0737 0.991355C14.9834 0.412577 17.9994 0.709617 20.7403 1.84493C23.4812 2.98025 25.8238 4.90284 27.4721 7.36957C29.1203 9.83631 30 12.7364 30 15.7031C30 19.6814 28.4197 23.4967 25.6066 26.3097C22.7936 29.1228 18.9783 30.7031 15 30.7031ZM6.507 19.0856C6.18444 18.7595 6.00352 18.3193 6.00352 17.8606C6.00352 17.4019 6.18444 16.9617 6.507 16.6356L7.39003 15.7576C7.54019 15.6061 7.71925 15.4863 7.91661 15.4053C8.11397 15.3243 8.32561 15.2838 8.53892 15.2862C8.75224 15.2886 8.96289 15.3338 9.1584 15.4192C9.35391 15.5045 9.53027 15.6283 9.67702 15.7831L11.237 17.4016C11.3114 17.4801 11.401 17.5426 11.5003 17.5853C11.5997 17.628 11.7066 17.6501 11.8148 17.6501C11.9229 17.6501 12.0299 17.628 12.1292 17.5853C12.2286 17.5426 12.3181 17.4801 12.3925 17.4016L20.3425 9.20663C20.4903 9.0507 20.6681 8.92627 20.8653 8.84083C21.0624 8.75538 21.2748 8.71069 21.4896 8.70943C21.7045 8.70817 21.9174 8.75036 22.1155 8.83349C22.3136 8.91661 22.4929 9.03895 22.6425 9.19314L23.5 10.0601C23.8147 10.3849 23.9907 10.8194 23.9907 11.2716C23.9907 11.7239 23.8147 12.1583 23.5 12.4831L12.9455 23.2086C12.7982 23.3611 12.622 23.4826 12.4271 23.5661C12.2322 23.6495 12.0226 23.6932 11.8107 23.6946C11.5987 23.696 11.3885 23.6551 11.1926 23.5742C10.9966 23.4933 10.8188 23.3741 10.6695 23.2236L6.507 19.0856Z"
                fill="#699F4C"
              />
            </svg>
          </button>
        </a>
          
        </div>
      </div>
    </div>
  );
};

export default DescriptionVehicleBroker;
