import React from "react";
import Images from "../../assets/ImagesVehicle/person.png";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";

const DescriptionVehicle = ({car}) => {

  const {t} = useTranslation();
  console.log(car)

  return (
    <div className="container mx-auto p-[80px] rounded-lg">
      <p className="text-2xl text-gray-800 text-right font-medium space-x-4 tracking-wide leading-loose">
        {t('des_vehicle_des')}
      </p>
      <div className=" rounded-lg">
        <div className="flex items-center p-12 ">
          <div>
          </div>
          <div className="ml-auto">
          </div>
        </div>
        <div className="text-center w-full bg-white">
          <span className="mr-[-110px] font-medium">
            {t('des_vehicle_price')}
            <span className="text-2xl">
              <span className="text-blue-600 text-bold text-2xl">
               {car.price}
              </span>
            </span>
          </span>
          <a href="#2">
            <button className="bg-[#FFA500] ml-[130px] hover:bg-amber-500 text-[#fff] w-[220px] rounded-full font-medium my-6 md:py-4 text-[23px]">
             {t('des_vehicle_now')}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DescriptionVehicle;
