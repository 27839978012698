import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import '../assets/Css/style.css';

const JoinTravelOffice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    license_number: '',
    bio: '',
    instagram: '',
    facebook: '',
  });

  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  const removeFile = () => {
    setFiles([]);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const base64File = files.length > 0 ? await convertToBase64(files[0]) : null;
      const data = {
        ...formData,
        image: base64File,
      };

      await axios.post('https://nawyapp.com/api/Travel_officee/', data);
      navigate('/login');
    } catch (error) {
      setError(t('error_submitting_form'));
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="min-h-screen bg-[#ebeafa] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
        <form onSubmit={handleSubmit} className="space-y-6 p-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">{t('join_travel_office')}</h2>
          
          {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}

          <FormField
            label={t('JoinTravel_number')}
            name="license_number"
            type="text"
            placeholder="5855524"
            value={formData.license_number}
            onChange={handleInputChange}
            required
          />

          <FormField
            label={t('JionInf_Instagram')}
            name="instagram"
            type="url"
            placeholder="https://www.instagram.com/"
            value={formData.instagram}
            onChange={handleInputChange}
            required
          />

          <FormField
            label={t('Facebook_link')}
            name="facebook"
            type="url"
            placeholder="https://www.facebook.com/"
            value={formData.facebook}
            onChange={handleInputChange}
            required
          />

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {t('image')}
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              {files.length === 0 ? (
                <div {...getRootProps()} className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>{t('upload_a_file')}</span>
                      <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1">{t('or_drag_and_drop')}</p>
                  </div>
                  <p className="text-xs text-gray-500">{t('png_jpg_gif_up_to_10mb')}</p>
                </div>
              ) : (
                <div className="flex items-center">
                  <img src={URL.createObjectURL(files[0])} alt="Uploaded file" className="h-32 object-cover" />
                  <button
                    type="button"
                    onClick={removeFile}
                    className="ml-4 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t('remove')}
                  </button>
                </div>
              )}
            </div>
          </div>

          <FormField
            label={t('description')}
            name="bio"
            as="textarea"
            rows="4"
            value={formData.bio}
            onChange={handleInputChange}
            required
          />

          <div className="flex items-center justify-between pt-6">
            <Link
              to="/"
              className="bg-gray-200 text-gray-700 px-6 py-2 rounded-md text-sm font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              {t('content_back')}
            </Link>
            <button
              type="submit"
              className="bg-[#FFA500] text-white px-6 py-2 rounded-md text-sm font-medium hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500]"
            >
              {t('JionInf_send')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const FormField = ({ label, name, as: Component = 'input', ...rest }) => (
  <div className="space-y-2">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <Component
      id={name}
      name={name}
      className="mt-1 block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
      {...rest}
    />
  </div>
);

export default JoinTravelOffice;