// src/components/PropertyList.jsx
import React, { useEffect, useState } from 'react';
import PropertyCard from './SimilarCards';
import Flat1 from "../../assets/Images/Flat1.png";
import Flat2 from "../../assets/Images/Flat2.png";
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const PropertyList = (id) => {

  const {t} = useTranslation();
  const [properties, setproperty] = useState([]);

  useEffect(() => {
    fetchproperty();
  }, [])
  const fetchproperty = async () => {
    await axios.get('https://nawyapp.com/api/similar/' + id.property_id).then(response => {
      const data = response.data;
      setproperty(data);
      
    });
  }

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {properties.map((property, index) => (
          <div className="">
          <PropertyCard key={index} property={property}  />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PropertyList;