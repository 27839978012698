import React, { useEffect, useState } from 'react'
import Carousel from './Carousel'
import DescriptionCarousel from './DescriptionCarousel'
import DescriptionProperty from './DescriptionProperty'
import Images from './Images'
import Videos from './Videos'
import DescriptionCard from './DescriptionCard'
// import SimilarCards from './SimilarCards'
import PropertyList from './PropertyList'
import axios from 'axios'
import { useParams } from "react-router-dom";

const DetailsCardsProperty = () => {
  const [DesCard, setDesCard] = useState(0);
  const [images,setimages] =useState([]);
  const [proId,setpeo] = useState(0);

  const { id } = useParams(); // احصل على id من الرابط

  const [property, setproperty] = useState([]);
  // const fetchimages = async () => {
      
  //   await axios.get(`https://nawyapp.com/api/showfiles`, {
  //     params: {
  //       type: 'property',
  //       id: property[0].id
  //     }
  //   } ).then(({ data }) => {
  //     setimages(data)
  //     // console.log(data)
  //   });
  // }

 
  // const longitude = property[0].location?.longitude;
 
  // console.log(longitude)

  const [lat,setlat]=useState(0);
  const [log,setlog]=useState(0);

  // useEffect(() => {
  //   fetchproperty();
  // }, [])
  const fetchproperty = async () => {
    await axios.get('https://nawyapp.com/api/property/' + id).then(({ data }) => {
      setlat(data[0].location.latitud)
      setpeo(data[0].id)
      setlog(data[0].location.longitude)
      setproperty(data)
    });
  }
  useEffect(() => {
    fetchproperty();

  }, [])


  return (
    <div className='bg-[#EBEAFA]'>
      <Carousel property_id={property[0]} proId={proId}  />
      <DescriptionCarousel property={property} />
      <DescriptionCard setDesCard={setDesCard} />
      {DesCard === 0 && <DescriptionProperty />}
      {DesCard === 1 && <Images images={images}  />}
      {DesCard === 1 && <Videos />}
      <div className="min-h-screen  ">
        {DesCard === 2 && <PropertyList property_id={property[0].id} />}
      </div>
    </div>
  )
}

export default DetailsCardsProperty