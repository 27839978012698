

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker, InfoWindow, Autocomplete } from "@react-google-maps/api";
import axios from "axios";
import { IoIosSearch } from "react-icons/io";

const MapComponent = () => {

  const [categoriesall, setCategories] = useState([]);
  useEffect(() => {
    fetchCategories();
  }, [])
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/Property_type').then(({ data }) => {
      setCategories(data)
    });
  }

  const sortproperty = async (id) => {

    await axios.get('https://nawyapp.com/api/sortproperty/' + id).then(({ data }) => {
      setproperties(data)
    });
  }
  





  const [selectedProperty, setSelectedProperty] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 24.7136, lng: 46.6753 }); // المركز الافتراضي للخريطة
  const navigate = useNavigate();
  const autocompleteRef = useRef(null); // مرجع للـ Autocomplete
  const [serarch, setsearch] = useState(false)

  const mapStyles = {
    height: "100vh",
    width: "100%"
  };
  const [properties, setproperties] = useState([])

  const detailsproperty = (id) => {
    navigate(`/details_cards_property/${id}`);
  }

  // const handleMarkerClick = (property) => {
  //   setSelectedProperty(property);
  //   navigate(`/property/${property.id}`);
  // };

  const api_properties = async () => {
    await axios.get('https://nawyapp.com/api/property',
    ).then(({ data }) => {
      setproperties(data)
    });
  }
  useEffect(() => {
    api_properties();
  }, [])

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      setMapCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  return (
    <div className="">
      <div className="flex flex-wrap justify-center gap-2 mb-4 bg-gray-100 p-4 rounded-lg shadow">
        {categoriesall.map((type) =>
          <button onClick={() => sortproperty(type.id)} type="button" className="p-3 bg-white hover:bg-[#800080] rounded-2xl m-2 hover:text-white">{type.name}</button>
        )}
      </div>
      <LoadScript googleMapsApiKey="AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY" libraries={["places"]}>
      <Autocomplete
            onLoad={ref => (autocompleteRef.current = ref)}
            onPlaceChanged={handlePlaceChanged}
          >
            <input
              type="text"
              placeholder="Search for a city"
              className="w-full p-2 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:outline-none"
              style={{
                boxSizing: "border-box",
                height: "40px",
                fontSize: "16px",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                top: "10px",
                zIndex: "10"
              }}
            />
          </Autocomplete>

        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={10}
          center={mapCenter} // تحريك الخريطة بناءً على الموقع الجديد
        >

          {properties.length === 0 ? '' :
            properties.map((property) => (
              <Marker
                key={property.id}
                position={{ lat: property.location.latitud, lng: property.location.longitude }}
                onClick={() => setSelectedProperty(property)}
              />
            ))}
          {selectedProperty && (
            <InfoWindow
              position={{ lat: selectedProperty.location.latitud, lng: selectedProperty.location.longitude }}
              onCloseClick={() => setSelectedProperty(null)}
            >
              <div>
                <h2>{selectedProperty.price}</h2>
                <p>{selectedProperty.allow_buy_or_sell}</p>
                <button onClick={() => detailsproperty(selectedProperty.id)}> عرض تفاصيل العقار</button>

              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;

