import React, { useState, useEffect, useRef } from 'react';
import { FaList, FaUser, FaBell, FaComment, FaMap, FaCog, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const SidebarC = () => {
  const [activeIcon, setActiveIcon] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const scrollRef = useRef(null);

  const icons = [
    { icon: FaList, label: 'الإحصائيات' },
    { icon: FaUser, label: 'ملفي الشخصي' },
    { icon: FaBell, label: 'الإشعارات' },
    { icon: FaComment, label: 'المحادثات' },
    { icon: FaMap, label: 'الخريطة' },
    { icon: FaCog, label: 'الإعدادات' },
  ];

  const handleIconClick = (index) => {
    setActiveIcon(activeIcon === index ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      setActiveIcon(null);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -50, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 50, behavior: 'smooth' });
    }
  };

  return (
    <div 
      className="fixed top-24 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md px-4"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Desktop Version */}
      <div 
        className={`hidden sm:block bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-full shadow-lg overflow-hidden transition-all duration-300 ease-in-out ${
          isHovered ? 'py-3 px-6' : 'py-2 px-4'
        }`}
        style={{
          transform: isHovered ? 'translateY(-5px)' : 'translateY(0)',
          boxShadow: isHovered ? '0 10px 25px rgba(0, 0, 0, 0.2)' : '0 5px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="flex items-center justify-between">
          {icons.map((item, index) => (
            <DesktopIcon 
              key={index}
              item={item}
              index={index}
              activeIcon={activeIcon}
              isHovered={isHovered}
              handleIconClick={handleIconClick}
            />
          ))}
        </div>
      </div>

      {/* Mobile Version */}
      <div className="sm:hidden relative">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
          <button onClick={scrollLeft} className="bg-white rounded-full p-1 shadow-md">
            <FaChevronLeft className="text-purple-600" />
          </button>
        </div>
        <div 
          ref={scrollRef}
          className="flex items-center space-x-4 overflow-x-auto scrollbar-hide py-2 px-8"
          style={{
            scrollSnapType: 'x mandatory',
            scrollPadding: '0 24px',
          }}
        >
          {icons.map((item, index) => (
            <MobileIcon 
              key={index}
              item={item}
              index={index}
              activeIcon={activeIcon}
              handleIconClick={handleIconClick}
            />
          ))}
        </div>
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
          <button onClick={scrollRight} className="bg-white rounded-full p-1 shadow-md">
            <FaChevronRight className="text-purple-600" />
          </button>
        </div>
      </div>

      <div 
        className={`mt-2 h-1 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full transition-all duration-300 ease-in-out ${
          isHovered ? 'opacity-100 w-1/2 mx-auto' : 'opacity-0 w-0'
        }`}
      ></div>
    </div>
  );
};

const DesktopIcon = ({ item, index, activeIcon, isHovered, handleIconClick }) => (
  <div
    className="relative group"
    onClick={() => handleIconClick(index)}
  >
    <div className="flex flex-col items-center">
      <item.icon 
        className={`text-2xl cursor-pointer transition-all duration-300 ${
          activeIcon === index ? 'text-yellow-300 transform scale-1.1' : 'text-white hover:text-yellow-200'
        }`}
      />
      <span className={`text-xs mt-1 transition-all duration-300 ${
        isHovered ? 'opacity-100' : 'opacity-0'
      }`}>
        {item.label}
      </span>
    </div>
    {activeIcon === index && (
      <div className="absolute -bottom-10 left-1/2 transform -translate-x-1/2 px-3 py-1 bg-white text-purple-600 text-xs rounded-full whitespace-nowrap shadow-lg transition-all duration-300 ease-in-out">
        {item.label}
      </div>
    )}
  </div>
);

const MobileIcon = ({ item, index, activeIcon, handleIconClick }) => (
  <div
    className={`flex flex-col items-center space-y-1 p-2 cursor-pointer transition-all duration-300 ${
      activeIcon === index ? 'bg-purple-100 rounded-full' : ''
    }`}
    onClick={() => handleIconClick(index)}
    style={{ scrollSnapAlign: 'center' }}
  >
    <item.icon 
      className={`text-xl ${
        activeIcon === index ? 'text-purple-600' : 'text-gray-600'
      }`}
    />
    <span className={`text-xs ${
      activeIcon === index ? 'text-purple-600 font-semibold' : 'text-gray-800'
    }`}>
      {item.label}
    </span>
  </div>
);

export default SidebarC;