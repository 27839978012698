import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Add2 = ({ updateFormData, updatecheckbox, formData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState("#000000");

  const handleNext = () => {
    navigate("/vehicle/add3");
  };

  // Predefined features for selection
  const features = [
    { name: "CD", label: t("add1_vehicle_cd") },
    { name: "air_conditioner", label: t("add1_vehicle_air") },
    { name: "usb", label: t("add1_vehicle_input") },
    { name: "Airbags", label: t("add1_vehicle_bags") },
    { name: "Electric_seats", label: t("add1_vehicle_seats") },
    { name: "Electric_windows", label: t("add1_vehicle_glass") },
  ];

  // Handle color selection for interior
  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
    updateFormData(e);
  };

  return (
    <div className="bg-[#EBEAFA] min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-3xl w-full">
        <h2 className="text-3xl font-semibold text-center mb-6">
          {t("add1_vehicle_wel")} <span>{t("add1_vehicle_name")}</span> {t("add1_vehicle_fill")}
        </h2>

        {/* Interior Color Selection */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">{t("add1_vehicle_inner")}</h3>
          <div className="flex items-center space-x-4">
            <label htmlFor="Interior_color" className="text-lg font-medium">
              {t("add1_vehicle_color")}:
            </label>
            <input
              type="color"
              id="Interior_color"
              name="Interior_color"
              value={selectedColor}
              onChange={handleColorChange}
              className="w-12 h-12 border-none outline-none cursor-pointer"
            />
            <span className="text-lg">{selectedColor}</span>
          </div>
        </div>

        {/* Features Selection */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4">{t("add1_vehicle_featu")}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {features.map((feature, index) => (
              <div key={index} className="flex items-center">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name={feature.name}
                    value="1"
                    onChange={updatecheckbox}
                    className="form-checkbox h-5 w-5 text-[#800080] border-gray-300 rounded"
                  />
                  <span className="text-lg">{feature.label}</span>
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-8">
          <Link to="/vehicle/add1">
            <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center shadow-md hover:bg-[#FFA500] transition duration-300">
              <svg
                width="15"
                height="15"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998"
                  stroke="#000"
                  strokeWidth="1.6"
                />
              </svg>
            </div>
          </Link>
          <span className="text-lg font-medium">2/6</span>
          <button
            onClick={handleNext}
            className="bg-[#FFA500] text-white rounded-full px-8 py-3 text-lg font-semibold shadow-md hover:bg-[#FF8C00] transition duration-300"
          >
            {t("add1_vehicle_next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add2;
