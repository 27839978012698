import { atom } from 'jotai';

const getUserFromLocalStorage = () => {
  const userString = localStorage.getItem('user');
  return userString ? JSON.parse(userString) : null;
};

export const userAtom = atom(getUserFromLocalStorage());

export const userDetailsAtom = atom({
  email: '',
  password: ''
});

export const usersAtom = atom([]);

export const setUserAtom = atom(
  (get) => get(userAtom),
  (get, set, newUser) => {
    set(userAtom, newUser);
    if (newUser) {
      localStorage.setItem('user', JSON.stringify(newUser));
    } else {
      localStorage.removeItem('user');
    }
  }
);