import React, {useEffect, useState} from 'react'
import Carousel from './Carousel'
import DescriptionCarousel from './DescriptionCarousel'
import Images from './Images'
import Videos from './Videos'
import DescriptionCard from './DescriptionCard'
import PropertyList from './PropertyList'
import DescriptionPropertyBroker from './DescriptionPropertyBroker'
import { useParams } from 'react-router-dom'
import axios from 'axios'


const DetailsCardsPropertyBroker = () => {
  const [DesCard, setDesCard] = useState(0);

  const { id } = useParams(); // احصل على id من الرابط

  const [property, setproperty] = useState([]);

  const [lat,setlat]=useState(0);
  const [log,setlog]=useState(0);

  useEffect(() => {
    fetchproperty();
  }, [])
  const fetchproperty = async () => {
    await axios.get('https://nawyapp.com/api/property/' + 1).then(({ data }) => {
      setlat(data[0].location.latitud)
      setlog(data[0].location.longitude)
      setproperty(data)
    });
  }

  return (
    <div className='bg-[#EBEAFA]'>
        <Carousel property_id={property[0]}/>
        <DescriptionCarousel property={property} />
      <DescriptionCard setDesCard={setDesCard}/>
        {DesCard === 0 && <DescriptionPropertyBroker/>}
        {DesCard === 1 && <Images property_id={property[0].id}  />}
        {DesCard === 1 && <Videos/>}
        <div className="min-h-screen  ">
        {DesCard === 2 && <PropertyList property_id={property[0].id} />}
      </div>
    </div>
    
  )
//   <div className='bg-[#EBEAFA]'>

  
//   <DescriptionCard setDesCard={setDesCard} />
//   {DesCard === 0 && <DescriptionProperty />}
  
//   {DesCard === 1 && <Videos />}
//   <div className="min-h-screen  ">
    
//   </div>
// </div>
}

export default DetailsCardsPropertyBroker