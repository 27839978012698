import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Header from "../header";
import { Link } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


const Add2 = ({ updateFormOrderTrovel }) => {

  const { t } = useTranslation();

  const [markers, setMarkers] = useState([]);

  const mapStyles = {
    height: "400px",
    width: "100%"
  };

  const defaultCenter = {
    lat: 41.3851, lng: 2.1734
  };
  // عند النقر على الخريطة لإضافة علامة
  const onMapClick = (event) => {
    if (markers.length < 2) {
      setMarkers([...markers, { lat: event.latLng.lat(), lng: event.latLng.lng() }]);
    }
  };

  return (
    <div>
      <Header
        title={t('travel_ser_top_header')}
        className="min-h-screen flex flex-col items-center"
      />
      <div className="flex flex-col items-center bg-[#EBEAFA]">
        <div className="w-full  max-w-5xl p-4 mt-10">
          <br /> <br />


          {/* خريطة الرحلة */}
          <div className="text-right mb-8">
            <h2 className="text-xl font-bold mb-4">{t('order_travel_add_itinerary')}</h2>
            <div className="bg-gray-100 p-4 rounded-lg mb-4">
              <LoadScript
                googleMapsApiKey='AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY'>
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={2}
                  center={defaultCenter}
                  onClick={onMapClick}
                >
                  {markers.map((marker, index) => (
                    <Marker key={index} position={marker} />
                  ))}
                </GoogleMap>
              </LoadScript>





            </div>
          </div>

          {/* نموذج المعلومات */}
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative">
                {markers.length === 0 ? '' : markers.length === 1 ?
                  <div className=""> 
                    <input type="text" name="latitudfor" value={markers[0].lat}
                      onChange={updateFormOrderTrovel} />
                    <input type="text" name="longitudefor" value={markers[0].lng}
                      onChange={updateFormOrderTrovel} />
                  </div>
                  :
                  <div>
                    <input type="text" name="latitudto" value={markers[1].lat}
                      onChange={updateFormOrderTrovel} />
                    <input type="text" name="longitudto" value={markers[1].lng}
                      onChange={updateFormOrderTrovel} />
                  </div>
                }


                <input
                  input type="text"
                  name="start_time"
                  onChange={updateFormOrderTrovel} id="dateInput" placeholder={t('order_travel_add_input_start')} onfocus="(this.type='date')" onblur="if(!this.value) this.type='text'"
                  // placeholder={t('order_travel_add_input_start')}
                  className="w-full p-2 pr-10 border text-right border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-purple-600"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />
                <FaCalendarAlt className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
              <div className="relative">
                <input
                  type="number"
                  name="flights_capacity"
                  onChange={updateFormOrderTrovel}
                  placeholder={t('order_travel_add_input_capacity')}
                  className="w-full p-2 pr-10 border text-right border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-purple-600"
                />
                <FaCalendarAlt className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div className="relative left-1/4 pt-4">
              <input
                type="text"
                name="end_time"
                onChange={updateFormOrderTrovel}
                id="dateInput" onfocus="(this.type='date')" onblur="if(!this.value) this.type='text'"
                placeholder={t('order_travel_add_input_end')}
                className="w-[49%] p-2 pr-10 border text-right border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-purple-600"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />
              <FaCalendarAlt className="absolute left-[46%] top-1/2 mt-2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>

          {/* أزرار التحكم */}
          <div className="flex justify-center p-12">
            <Link to="/order_travel/add/">
              <button className="bg-gray-300 text-black mx-[80px] px-7 py-3 rounded-3xl hover:bg-[#800080] hover:text-white">
                {t('travel_ser_des_order')}
              </button>
            </Link>
            <Link to="/order_travel/add">
              <button className="bg-[#FFA500] text-white mx-[90px] px-7 py-3 rounded-3xl hover:bg-[#800080]">
                {t('add1_vehicle_next')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add2;