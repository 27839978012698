import React, {useEffect, useState} from 'react'
import Carousel from './Carousel'
import DescriptionCarousel from './DescriptionCarousel'
import DescriptionTravelOrder from './DescriptionTravelOrder'
import Images from './Images'
import Videos from './Videos'
import DescriptionCard from './DescriptionCard'
import TravelOrderList from './TravelOrderList'
import ImagesTravelOrder from './ImagesTravelOrder'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const DetailsCardsProperty = () => {
  const [DesCard, setDesCard] = useState(0);

  const { id } = useParams(); // احصل على id من الرابط

  const [property, setproperty] = useState([]);

  const [lat,setlat]=useState(0);
  const [log,setlog]=useState(0);

  useEffect(() => {
    fetchproperty();
  }, [])
  const fetchproperty = async () => {
    await axios.get('https://nawyapp.com/api/property/' + 1).then(({ data }) => {
      setlat(data[0].location.latitud)
      setlog(data[0].location.longitude)
      setproperty(data)
    });
  }


  return (
    <div className='bg-[#EBEAFA]'>
     <Carousel property_id={property[0]}/>
     <DescriptionCarousel property={property} />
      <DescriptionCard setDesCard={setDesCard}/>
        {DesCard === 0 && <DescriptionTravelOrder/>}
        {DesCard === 1 && <ImagesTravelOrder/>}
        {DesCard === 1 && <Images/>}
        {DesCard === 1 && <Videos/>}
        <div className="min-h-screen  ">
        {DesCard === 2 && <TravelOrderList />}
      </div>
    </div>
  )




  

}

export default DetailsCardsProperty