import React, { useEffect, useRef, useState } from "react";
import image from "../../assets/Images/laptop.jpg";
import image1 from "../../assets/ImagesInfluancers/broker.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton, InstapaperShareButton } from 'react-share';
import { FacebookIcon, XIcon, WhatsappIcon, TelegramIcon, InstapaperIcon } from 'react-share';
import { useTranslation } from "react-i18next";


// const images = [image, image1, image, image1, image, image1, image];

const Carousel = (property, proId) => {



  // const imageUrl = `https://nawyapp.com/storage/${imageName}`;

  // useEffect(() => {
  //   showimages();
  // }, [])
  // const properteyId = property.property_id.id;
 



  const [images,setimages] =useState([]);

  useEffect(() => {
    fetchimages(); 
  }, [])

  const urlRef = useRef(null);
  const fetchimages = async () => {
    await axios.get(`https://nawyapp.com/api/showfiles`, {
      params: {
        type: 'property',
        id: 4
      }
    }).then(({ data }) => {
      setimages(data)
      // console.log(data)
    });
  }

  // دالة نسخ الـ URL
  const copyURL = (e) => {
    e.preventDefault();
    // تحديد النص داخل الحقل
    urlRef.current.select();
    urlRef.current.setSelectionRange(0, 99999); // للأجهزة المحمولة

    // نسخ النص إلى الحافظة
    document.execCommand("copy");

    // إعلام المستخدم بأن النص تم نسخه
    alert("تم نسخ الـ URL: " + urlRef.current.value);
  };

  const [currentImageIndex, setCurrentImageIndex] = useState('');
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [Favorite_color,setFavorite] = useState(false)

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const shareUrl = window.location.href; // الرابط الحالي للصفحة
  const title = property.title || "Check out this property";
  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };

  // const pro= id.property_id
  const Favorite = async () => {
    const formdata = new FormData();
    formdata.append('favoritable_type', 'Property')
    formdata.append('favoritable_id', property.property_id.id)
    await axios.post('https://nawyapp.com/api/Favorite', formdata,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        setFavorite(true)
  
      }).catch((error) => {
        console.log(error);
      });

  }


  return (
    <div className="relative w-full h-60 sm:h-80 md:h-96 overflow-hidden">
      {/* Big image slider */}
      {/* love icon */}
      <a onClick={Favorite} className="absolute top-4 right-28 z-50">
        <div className="bg-white p-2 w-10 h-10 shadow-lg rounded-full">
        {Favorite_color === false?
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 38 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.771 2C31.8679 2 36 6.33358 36 11.6791C36 21.3581 25.093 30.1573 19.22 32.204C13.347 30.1573 2.44 21.3581 2.44 11.6791C2.44 6.33358 6.57207 2 11.669 2C14.7901 2 17.5504 3.62521 19.22 6.11273C20.071 4.84143 21.2016 3.80391 22.516 3.08801C23.8304 2.3721 25.2899 1.9989 26.771 2Z"
              stroke="#000929"
              strokeWidth="3"
            />
          </svg> :
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 38 35"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.771 2C31.8679 2 36 6.33358 36 11.6791C36 21.3581 25.093 30.1573 19.22 32.204C13.347 30.1573 2.44 21.3581 2.44 11.6791C2.44 6.33358 6.57207 2 11.669 2C14.7901 2 17.5504 3.62521 19.22 6.11273C20.071 4.84143 21.2016 3.80391 22.516 3.08801C23.8304 2.3721 25.2899 1.9989 26.771 2Z"
              stroke="#000929"
              strokeWidth="3"
            />
          </svg>
        }
        </div>
      </a>
      {/* return icon  */}
      <a href="#1" className="absolute top-4 right-16 z-50">
        <div className="bg-white p-2 w-10 h-10 shadow-lg rounded-full">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.0645695 10.9996C0.0645695 11.3986 0.221731 11.7811 0.50148 12.0632C0.781229 12.3453 1.16065 12.5038 1.55628 12.5038H18.8392L12.4338 18.9595C12.2951 19.0994 12.1851 19.2654 12.11 19.4481C12.035 19.6309 11.9964 19.8267 11.9964 20.0245C11.9964 20.2222 12.035 20.4181 12.11 20.6008C12.1851 20.7835 12.2951 20.9496 12.4338 21.0894C12.5725 21.2293 12.7372 21.3402 12.9184 21.4159C13.0996 21.4916 13.2938 21.5305 13.4899 21.5305C13.6861 21.5305 13.8803 21.4916 14.0615 21.4159C14.2427 21.3402 14.4074 21.2293 14.5461 21.0894L23.4963 12.0646C23.6352 11.9248 23.7455 11.7589 23.8207 11.5761C23.8959 11.3934 23.9346 11.1975 23.9346 10.9996C23.9346 10.8018 23.8959 10.6059 23.8207 10.4231C23.7455 10.2404 23.6352 10.0744 23.4963 9.9347L14.5461 0.909859C14.266 0.627422 13.8861 0.46875 13.4899 0.46875C13.0938 0.46875 12.7139 0.627422 12.4338 0.909859C12.1537 1.1923 11.9964 1.57536 11.9964 1.97479C11.9964 2.37422 12.1537 2.75728 12.4338 3.03972L18.8392 9.49549H1.55628C1.16065 9.49549 0.781229 9.65396 0.50148 9.93604C0.221731 10.2181 0.0645695 10.6007 0.0645695 10.9996Z"
              fill="#000929"
            />
          </svg>
        </div>
      </a>
      {/* share icon  */}
      <a
        onClick={() => setShowModal(true)}
        className="absolute top-4 right-4 z-50">
        <div className="bg-white p-2 w-10 h-10 shadow-lg rounded-full">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.6504 20.4004C27.4724 20.4004 29.7504 22.6784 29.7504 25.5004C29.7504 28.3224 27.4724 30.6004 24.6504 30.6004C21.8284 30.6004 19.5504 28.3224 19.5504 25.5004C19.5504 25.0924 19.6014 24.7184 19.7034 24.3274L12.2574 20.4174C11.3224 21.4544 9.99639 22.1004 8.50039 22.1004C5.67839 22.1004 3.40039 19.8224 3.40039 17.0004C3.40039 14.1784 5.67839 11.9004 8.50039 11.9004C9.99639 11.9004 11.3224 12.5634 12.2574 13.5834L19.7034 9.67339C19.6014 9.28239 19.5504 8.90839 19.5504 8.50039C19.5504 5.67839 21.8284 3.40039 24.6504 3.40039C27.4724 3.40039 29.7504 5.67839 29.7504 8.50039C29.7504 11.3224 27.4724 13.6004 24.6504 13.6004C23.1544 13.6004 21.8284 12.9374 20.8934 11.9174L13.4474 15.8274C13.6533 16.5958 13.6533 17.4049 13.4474 18.1734L20.8934 22.0834C21.8284 21.0464 23.1544 20.4004 24.6504 20.4004Z"
              fill="#000929"
            />
          </svg>
        </div>
      </a>
      <div
        className="absolute inset-0 w-full h-full bg-center bg-cover"
        style={{ backgroundImage: `url(${currentImageIndex})` }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-20"></div>

      {/* small images sliders */}
      <div className="absolute inset-x-0 bottom-2 sm:bottom-4 md:bottom-6 flex justify-center items-center overflow-x-auto">
        <div className="flex space-x-1 sm:space-x-2 px-2">
          {images.map((index) => (
            <button
              key={index.id}
              className={`w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 border-2 rounded-lg flex-shrink-0 ${index === currentImageIndex
                ? "border-purple-500"
                : "border-gray-200"
                }`}
              onClick={() => goToSlide(`https://nawyapp.com/storage/${index.url_file}`)}
            >
              <img
                src={ `https://nawyapp.com/storage/${index.url_file}`}
                alt={`Thumbnail ${index}`}
                className="w-full h-full object-cover rounded-lg"
              />
            </button>
          ))}
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form action="" method="post">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
                    {t('share-p')}
                  </h3>
                  <div className="">
                    <input type="text" name="" ref={urlRef} className="p-2 border-2 rounded-full border-[#FFA500] w-[350px] mx-9" value={window.location.href} id="" />
                    <button className="p-2 -mx-6 bg-[#800080] rounded-xl text-white" onClick={copyURL}>نسخ</button>
                     <br /> <br />
                    <div className="flex justify-center">
                      <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon className="m-1" size={32} round />
                      </WhatsappShareButton>
                      <TelegramShareButton url={shareUrl} quote={title}>
                        <TelegramIcon className="m-1" size={32} round />
                      </TelegramShareButton>
                      <FacebookShareButton url={shareUrl} quote={title}>
                        <FacebookIcon className="m-1" size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton url={shareUrl} title={title}>
                        <XIcon className="m-1" size={32} round />
                      </TwitterShareButton>
                    </div>

                  </div>

                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                    onClick={() => setShowModal(false)}
                  >
                    {t('profile_close')}
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      )}


    </div>
  );
};

export default Carousel;