import React, { useEffect, useState } from "react";
import { FaBath, FaBed } from "react-icons/fa";
import { BsTextareaResize } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import axios from "axios";

const SimilarCards = ({ property }) => {

  const [image, setImage] = useState('')

  useEffect(() => {
    fetchproperty();
  }, [])
  const fetchproperty = async () => {


    await axios.get( `https://nawyapp.com/api/showimage` , {
      params: {
        model: 'property',
        id: property.id
      }
    }).then(response => {
      const data = response.data;
      setImage(data.url_file);
    });
  }


  console.log(image)
  return (
    // <div className=""></div>
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl">
      <div className="relative">
        <img
          className="w-full h-48 object-cover"
          src={ `https://nawyapp.com/storage/${image}` }
          alt={'property'}
        />

      </div>
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-semibold text-right">{property.property_type.name}</h3>
          <div className="flex items-center">
            <FaStar className="text-yellow-400 mr-1" />
            {/* <span className="text-sm font-medium">{rating}</span> */}
          </div>
        </div>
        <p className="text-sm text-gray-600 mb-4">{property.location.city} -{property.location.country} </p>
        <div className="flex justify-between items-center mb-4">
          <span className="text-xl font-bold text-blue-600">{property.price}</span>
          <div className="flex space-x-3 text-sm text-gray-600">
            <div className="flex items-center">
              <BsTextareaResize className="mr-1 text-[#800080]" />
              <span>{property.square_footage}</span>
            </div>
            <div className="flex items-center">
              <FaBath className="mr-1 text-[#800080]" />
              <span>{property.number_of_bathroom}</span>
            </div>
            <div className="flex items-center">
              <FaBed className="mr-1 text-[#800080]" />
              <span>{property.number_of_rooms}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimilarCards;