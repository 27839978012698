import React from 'react'
import Flat2 from '../assets/Images/Flat1.png'
import Flat1 from '../assets/Images/Flat2.png'
import { FaBath, FaBed, FaStar, FaMapMarkerAlt } from 'react-icons/fa';
import { BsTextareaResize } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function OrderComponents() {
    const {t} = useTranslation();
    const orders = [
        {
            id: 1,
            name: "رحلة القارب الزجاجي",
            location: "شرم الشيخ - مصر",
            image: Flat2,
            price: " ريال 3500 ",
            rating: "4.1",
        },
        {
            id: 2,
            name: "رحلة القارب الزجاجي",
            location: "شرم الشيخ - مصر",
            image: Flat1,
            price: " ريال 3500 ",
            rating: "4.1",
        },
        {
            id: 3,
            name: "رحلة القارب الزجاجي",
            location: "شرم الشيخ - مصر",
            image: Flat2,
            price: " ريال 3500 ",
            rating: "4.1",
        },
        {
            id: 4,
            name: "رحلة القارب الزجاجي",
            location: "شرم الشيخ - مصر",
            image: Flat1,
            price: " ريال 3500 ",
            rating: "4.1",
        },
    ];

    return (
        <div className='bg-[#ebeafa] p-4'>
            <section className="grid sm:grid-cols-1 lg:w-[70%] lg:grid-cols-2 w-full lg:ml-64 gap-4">
                {orders.map((order) => (
                    <div key={order.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
                        {/* Desktop Layout */}
                        <div className="hidden sm:flex justify-between p-4">
                            <div className="flex">
                                <img src={order.image} className="w-36 h-36 object-cover rounded" alt={order.name} />
                                <div className="ml-4">
                                    <p className='bg-green-200 text-sm text-green-700 inline-block px-2 py-1 rounded-full'>{t('details_Licensed')}</p>
                                    <h2 className='text-xl font-semibold mt-2'>{order.name}</h2>
                                    <p className='text-gray-600 mt-1'>{order.location}</p>
                                    <div className='flex items-center mt-2'>
                                        <FaStar className="text-yellow-400 mr-1" />
                                        <span>{order.rating}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="text-right">
                                <div className="text-blue-800 text-xl font-bold mb-4">{order.price}</div>
                                <div className='flex justify-end space-x-2 text-sm text-gray-600'>
                                    <span className='flex items-center'><BsTextareaResize className='mr-1' /> 5x7</span>
                                    <span className='flex items-center'><FaBath className='mr-1' /> 9 {t('details_bathroom')}</span>
                                    <span className='flex items-center'><FaBed className='mr-1' /> 3 {t('details_rooms')}</span>
                                </div>
                            </div>
                        </div>

                        {/* Mobile Layout */}
                        <div className="sm:hidden">
                            <img src={order.image} className="w-full h-48 object-cover" alt={order.name} />
                            <div className="p-4">
                                <div className="flex justify-between items-center mb-2">
                                    <h2 className='text-lg font-semibold'>{order.name}</h2>
                                    <p className='bg-green-200 text-xs text-green-700 px-2 py-1 rounded-full'>{t('details_Licensed')}</p>
                                </div>
                                <p className='text-sm text-gray-600 flex items-center mb-2'>
                                    <FaMapMarkerAlt className="mr-1 text-gray-400" /> {order.location}
                                </p>
                                <div className='flex justify-between items-center mb-3'>
                                    <div className='flex items-center'>
                                        <FaStar className="text-yellow-400 mr-1" />
                                        <span className="text-sm">{order.rating}</span>
                                    </div>
                                    <span className="text-blue-800 font-bold">{order.price}</span>
                                </div>
                                <div className='flex justify-between text-xs text-gray-600 mb-4'>
                                    <span className='flex items-center'><BsTextareaResize className='mr-1' /> 5x7</span>
                                    <span className='flex items-center'><FaBath className='mr-1' /> 9 {t('details_bathroom')}</span>
                                    <span className='flex items-center'><FaBed className='mr-1' /> 3 {t('details_rooms')}</span>
                                </div>
                            </div>
                        </div>

                        {/* Common Footer for both layouts */}
                        <div className="px-4 py-3 bg-gray-50">
                            <div className="flex justify-center space-x-4">
                                <button className='rounded-full p-2 bg-red-600 text-white hover:bg-red-700 transition duration-300 flex items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                    <span className='ml-2'>{t('order_refuse')}</span>
                                </button>
                                <button className='rounded-full p-2 bg-green-600 text-white hover:bg-green-700 transition duration-300 flex items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                                    </svg>
                                    <span className='ml-2'>{t('order_accetance')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default OrderComponents