import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NotificationNavItem = ({ icon, label, isActive, onClick }) => (
  <button
    className={`flex items-center justify-center space-x-2 py-2 px-4 rounded-full transition-all duration-300 ${
      isActive
        ? 'bg-white text-purple-600 shadow-md'
        : 'text-gray-600 hover:bg-white/50'
    }`}
    onClick={onClick}
  >
    <span className="text-xl">{icon}</span>
    <span className="hidden sm:inline">{label}</span>
  </button>
);

const Notifications = ({ setNotifi }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(2);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    setNotifi(tabIndex);
  };

  return (
    <header className="bg-purple-100 shadow-sm">
      <div className="max-w-4xl mx-auto px-4 py-2">
        <nav className="bg-purple-50 p-1 rounded-full shadow-md">
          <ul className="flex justify-center space-x-2">
            <li>
              <NotificationNavItem
                icon="🔔"
                label={t('Notifications_title')}
                isActive={activeTab === 2}
                onClick={() => handleTabChange(2)}
              />
            </li>
            <li>
              <NotificationNavItem
                icon="✉️"
                label={t('Notifications_Messages')}
                isActive={activeTab === 1}
                onClick={() => handleTabChange(1)}
              />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Notifications;