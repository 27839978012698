import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GoogleMap, LoadScript, Marker, } from '@react-google-maps/api';

const AboutInfluancer = ({ bio , center} ) => {

  const { t } = useTranslation();
  const mapStyles = {
    height: "400px",
    width: "100%"
  };


  return (
    <div className="grid grid-cols-1 w-full h-auto mx-auto p-[80px] rounded-lg">
      <p className="text-2xl text-gray-800 text-right font-medium pr-12 space-y-1 tracking-wide leading-loose">
      {bio}
      </p>
      <div className="mt-8">
        <h3 className="text-center pl-12 text-lg font-bold">{t('about_b_address')}</h3>
        <div className="mt-4">
        <LoadScript googleMapsApiKey="AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={13}
              center={ center }
            >
              <Marker position={ center } />
            </GoogleMap>
          </LoadScript>
 
        </div>
      </div>
      <div className="text-center mb-[-103px]">
        <span className="mr-[-110px] font-bold">
          {t('inf_ser_about_pay')}<span className="text-2xl text-[#990033]">{t('inf_ser_about_pay1')}</span>{t('inf_ser_about_pay2')}
        </span>
        <Link to="">
          <button className="bg-[#FFA500] ml-[130px] hover:bg-amber-500 text-[#fff] w-[200px] rounded-full font-medium my-6 md:py-3 text-[23px]">
            {t('inf_ser_about_now')}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AboutInfluancer;
