import React, { useRef, useState } from "react";
import image from "../../assets/ImagesBrokers/broker.jpg";
import { FaShareAlt, FaArrowRight, FaRegHeart, FaQrcode } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from "react-share";

const BackgroundB = ({ id, username, country, city, imageUrl, type }) => {
  const [showQR, setShowQR] = useState(false);
  const qrRef = useRef();

  const handleGenerateQR = () => {
    setShowQR(true);
  };

  const handleDownloadQR = (e) => {
    e.preventDefault();
    if (qrRef.current) {
      toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'qr-code.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error('Failed to download QR code', err);
        });
    }
  };


  const delete_api = async (id) => {
    await axios.delete('https://nawyapp.com/api/Favorite/' + id
    ).then(() => {
      setFavoriteColor(false);
    });
  }

  console.log(imageUrl)
  const urlRef = useRef(null);

  // دالة نسخ الـ URL
  const copyURL = (e) => {
    e.preventDefault();
    // تحديد النص داخل الحقل
    urlRef.current.select();
    urlRef.current.setSelectionRange(0, 99999); // للأجهزة المحمولة
    // نسخ النص إلى الحافظة
    document.execCommand("copy");
    // إعلام المستخدم بأن النص تم نسخه
    alert("تم نسخ الـ URL: " + urlRef.current.value);
  };

  const shareUrl = window.location.href; // الرابط الحالي للصفحة
  const title = username || "Check out this property";

  const [Favorite_color, setFavoriteColor] = useState(false);

  const handleFavoriteToggle = () => {
    if (Favorite_color) {
      delete_api();
    } else {
      Favorite();
    }
  };
  const [showModal, setShowModal] = useState(false);

  const Favorite = async () => {
    const token = localStorage.getItem('token');
    const formdata = new FormData();
    formdata.append('favoritable_type', type)
    formdata.append('favoritable_id', id)
    await axios.post('https://nawyapp.com/api/Favorite', formdata,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        setFavoriteColor(true)
      }).catch((error) => {
        console.log(error);
      });
  }
  const { t } = useTranslation();
  return (
    <div className="w-full h-screen">
      <div
        className="w-full h-96 bg-contain bg-no-repeat bg-center relative"
        style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: "cover" }}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

        <div className="flex space-x-[20px]  mt-4 absolute right-[160px]">
          {/* {Favorite_color === false ?
            <div className="border-2 p-2 bg-white rounded-full">
              <button onClick={() => Favorite} className="flex items-center text-gray-600">
                <FaRegHeart className="size-7  text-black" />
              </button>
            </div>
            :
            <div className="border-2 p-2 bg-white rounded-full">
              <button onClick={()=> delete_api(id)} className="flex items-center text-gray-600">
                <FaRegHeart className="size-7  text-red-700" />
              </button>
            </div>
          } */}
          <div className="border-2 p-2 bg-white rounded-full">
            {/* {Favorite_color === false ? (
                <button onClick={Favorite} className="flex items-center text-gray-600">
                    <FaRegHeart className="size-7 text-black" />
                </button>
            ) : (
                <button onClick={delete_api(id)} className="flex items-center text-gray-600">
                    <FaRegHeart className="size-7 text-red-700" />
                </button>
            )} */}

            <button onClick={handleFavoriteToggle} className="flex items-center text-gray-600">
              <FaRegHeart className={`size-7 ${Favorite_color ? 'text-red-700' : 'text-black'}`} />
            </button>
          </div>

          <div className="border-2 p-2 bg-white rounded-full">
            <button onClick={() => setShowModal(true)} className="flex items-center text-gray-600">
              <FaShareAlt className="size-7 text-black" />
            </button> 
          </div>


          <Link to="">

            <div className="border-2 p-2 bg-white rounded-full">
              <button onClick={handleGenerateQR} className="flex items-center text-gray-600">
                <FaQrcode className="size-7 text-black" />
              </button>
            </div>
          </Link>

          <Link to="">
            <div className="border-2 p-2 ml-[20px] bg-white rounded-full">
              <button className="flex items-center text-gray-600">
                <FaArrowRight className="size-7 text-black" />
              </button>
            </div>
          </Link>
        </div>
      </div>
      <div className="bg-white mb-[40px]">
        <p className="text-right pr-8 pt-2 font-bold text-[27px]">
          {username}

        </p>


        <p className="text-right pr-8 p-2 text-gray-600 text-[18px]">
          {country} - {city}

        </p>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form action="" method="post">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
                    {t('share-p')}
                  </h3>
                  <div className="">
                    <input type="text" name="" ref={urlRef} className="p-2 border-2 rounded-full border-[#FFA500] w-[350px] mx-9" value={window.location.href} id="" />
                    <button className="p-2 -mx-6 bg-[#800080] rounded-xl text-white" onClick={copyURL}>نسخ</button>
                    <br /> <br />
                    <div className="flex justify-center">
                      <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon className="m-1" size={32} round />
                      </WhatsappShareButton>
                      <TelegramShareButton url={shareUrl} quote={title}>
                        <TelegramIcon className="m-1" size={32} round />
                      </TelegramShareButton>
                      <FacebookShareButton url={shareUrl} quote={title}>
                        <FacebookIcon className="m-1" size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton url={shareUrl} title={title}>
                        <XIcon className="m-1" size={32} round />
                      </TwitterShareButton>
                    </div>

                  </div>

                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                    onClick={() => setShowModal(false)}
                  >
                    {t('profile_close')}
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      )}
      {/* {showQRCode && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form action="" method="post">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
                    {t('share-p')}
                  </h3>
                  <div>
                    <QRCode value={currentUrl} />
                    <button onClick={() => {
                      const canvas = document.querySelector('canvas');
                      const pngUrl = canvas.toDataURL('image/png');
                      const downloadLink = document.createElement('a');
                      downloadLink.href = pngUrl;
                      downloadLink.download = 'QRCode.png';
                      downloadLink.click();
                    }}>
                      حفظ QR Code
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      )} */}

      {showQR && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form action="" method="post">

                <div >
                  <div ref={qrRef} className="flex justify-center" style={{ margin: '20px' }}>
                    <QRCode value={window.location.href} />
                  </div>
                  <div className="flex justify-center">
                  <button className="p-3 border-2 bg-[#800080] text-white " onClick={handleDownloadQR}>تحميل QR Code</button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                    onClick={() => setShowQR(false)}
                  >
                    {t('profile_close')}
                  </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default BackgroundB;
