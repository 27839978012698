import React from "react";
import Vehicle from "../../assets/ImagesVehicle/car.png";
import { useTranslation } from "react-i18next";
// import Nawi from "../../assets/ImagesVehicle/car.png";

// const images = [Vehicle, Vehicle, Vehicle, Vehicle, Vehicle, Vehicle, Vehicle, Vehicle, Vehicle];

const ImagesvVehicle = ({images}) => {

  const {t} = useTranslation();

  
  return (
    <div className="flex justify-center items-center">
    
      <div className="grid sm:grid-cols-3 gap-4 p-12 mt-[70px] w-[80%] text-center object-cover">
        {images.map((index) => (
          <button
            key={index.id}
            className={"sm:w-auto object-cover w-full h-full"}
          >
            {index.type=== 'image'?
                 <img
                 src={`https://nawyapp.com/storage/${index.url_file}`}
                 alt={`Thumbnail ${index.type}`}
                 className="w-[500px] h-[400px] object-cover"
               /> :
               <video className="w-full h-full sm:w-auto object-cover" controls>
               <source src={`https://nawyapp.com/storage/${index.url_file}`} type="video/mp4" />
             </video>
            
          }

       
          </button>
        ))}
      </div>
    </div>
  );
};

export default ImagesvVehicle;
