import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtom } from 'jotai';
import axios from "axios";
import photo from "../assets/Images/laptop.jpg";
import { userAtom, setUserAtom } from "../store/login-store";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [user] = useAtom(userAtom);
  const [, setUser] = useAtom(setUserAtom);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const login_api = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://nawyapp.com/api/login', { email, password });
      localStorage.setItem('token', response.data.access_token);
      setUser(response.data.user); // This will also update localStorage
      setNotification({ type: 'success', message: t('login_successful') });
      setTimeout(() => navigate('/'), 2000);
    } catch (error) {
      console.error('Login error:', error);
      setNotification({ type: 'error', message: t('login_failed') });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    // Implement forgot password logic here
    console.log("Forgot password clicked");
  };


  const signInWithGoogle = async () => {
    // Implement Google Sign-In logic here
    setNotification({ type: 'info', message: t('google_signin_not_implemented') });
  };

  return (
    <div className="text-[#333]">
      <div className="min-h-screen flex flex-col justify-between items-center py-6 px-4">
        {notification && (
          <div className={`fixed top-0 left-0 right-0 p-4 text-white text-center ${
            notification.type === 'success' ? 'bg-green-500' :
            notification.type === 'error' ? 'bg-red-500' : 'bg-blue-500'
          }`}>
            {notification.message}
          </div>
        )}
        <img
          className="w-[500px] mx-auto my-4 justify-between items-center"
          src={photo}
          alt="/"
        />
        <div className="grid md:grid-cols-2 items-center gap-10 max-w-6xl w-full">
          <form className="space-y-6 max-w-md md:ml-auto max-md:mx-auto w-full mr-[-225px]" 
            onSubmit={login_api}>
            <h3 className="text-3xl font-extrabold mb-8 text-center">
              {t('login_signin')}
            </h3>
            <div>
              <input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                autoComplete="email"
                required
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-full outline-[#FFA500] text-right"
                placeholder={t('login_email')}
              />
            </div>
            <div>
              <input
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                autoComplete="current-password"
                required
                className="bg-gray-100 w-full text-sm px-4 py-3.5 rounded-full outline-[#FFA500] text-right"
                placeholder={t('login_password')}
              />
            </div>
            <div className="flex items-center justify-between ml-[20px]">
              <div className="text-sm">
                <button
                  type="button"
                  onClick={handleForgotPassword}
                  className="text-blue-600 hover:text-[#800080] focus:outline-none"
                >
                  {t('login_password_forget')}
                </button>
              </div>
            </div>
            <div className="!mt-4">
              <button
                type="submit"
                className="w-[66%] ml-[70px] shadow-xl py-2.5 font-bold text-white bg-[#FFA500] h-14 focus:outline-none rounded-full"
                disabled={loading}
              >
                {loading ? t('logging_in') : t('login_signin')}
              </button>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 h-0.5 bg-gray-300"></div>
              <div className="mx-4 text-gray-500">{t('login_or')}</div>
              <div className="w-1/2 h-0.5 bg-gray-300"></div>
            </div>
            <div className="space-x-6 flex justify-center">
              <button
                type="button"
                onClick={signInWithGoogle}
                className="w-44 shadow-xl py-2.5 font-bold text-white bg-slate-300 h-14 focus:outline-none rounded-full flex items-center justify-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48">
                  <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
                  <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
                  <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
                  <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
                </svg>
                <span className="ml-2">{t('login_with_google')}</span>
              </button>
            </div>
            <p className="font-bold ml-[120px]">
              {t('login_acount')}{' '}
              <Link to="/register" className="text-blue-600 underline hover:text-[#800080]">
                {t('login_create')}
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;