import axios from 'axios';
import { t } from 'i18next';
import React from 'react';
import {format} from 'date-fns';


const DescriptionCarousel = ({ property }) => {
  const formData = (dataString) =>{


    // return format(new Data(dataString), 'dd/mm/yyyy');
  };
  return (
    <div>
      { property.map((p,index) =>(
             <div key={index} className='bg-white'>
             <p className='text-right pr-8 font-bold text-[25px]'>{p.property_type.name } {p.allow_buy_or_sell} 
              <br/><span className='mr-4 p-2 text-[16px] text-[#800080] font-small'>{p.created_at}</span></p>
             <p className='text-right pr-8  p-2  text-[18px]'>{p.location_id}</p>
           </div>
      ))
      }
    </div>
  )
}

export default DescriptionCarousel
