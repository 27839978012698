import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import { FaEdit, FaCamera } from 'react-icons/fa';
import defaultCoverImage from '../assets/Images/Mask.png';
import defaultProfileImage from '../assets/ImagesVehicle/person.png';

const EditProfileComponent = ({ setNotifi }) => {
  const { t } = useTranslation();
  const [coverImage, setCoverImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const onDropCover = useCallback((acceptedFiles) => {
    setCoverImage(URL.createObjectURL(acceptedFiles[0]));
  }, []);

  const onDropProfile = useCallback((acceptedFiles) => {
    setProfileImage(URL.createObjectURL(acceptedFiles[0]));
  }, []);

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    accept: "image/*",
    onDrop: onDropCover,
  });

  const { getRootProps: getProfileRootProps, getInputProps: getProfileInputProps } = useDropzone({
    accept: "image/*",
    onDrop: onDropProfile,
  });

  const stats = [
    { value: 100, label: 'editinf_buying' },
    { value: 100, label: 'editinf_sale' },
    { value: 100, label: 'editinf_review' },
  ];

  const tabs = [
    { id: 1, label: 'editinf_Transfers' },
    { id: 2, label: 'editinf_purchase' },
    { id: 3, label: 'editinf_sale' },
  ];

  return (
    <div className="w-full bg-gray-100">
      <CoverImage coverImage={coverImage} getCoverRootProps={getCoverRootProps} getCoverInputProps={getCoverInputProps} />
      <ProfileImage profileImage={profileImage} getProfileRootProps={getProfileRootProps} getProfileInputProps={getProfileInputProps} />
      <StatsSection stats={stats} t={t} />
      <TabsSection tabs={tabs} setNotifi={setNotifi} t={t} />
    </div>
  );
};

const CoverImage = ({ coverImage, getCoverRootProps, getCoverInputProps }) => (
  <div className="relative h-48 sm:h-64 md:h-80 lg:h-96 bg-cover bg-center transition-all duration-300 ease-in-out"
    style={{ backgroundImage: `url(${coverImage || defaultCoverImage})` }}>
    <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out"></div>
    <div {...getCoverRootProps()} className="absolute bottom-4 right-4 cursor-pointer group">
      <input {...getCoverInputProps()} />
      <FaEdit className="text-white text-2xl group-hover:text-purple-500 transition-colors duration-200" />
    </div>
  </div>
);

const ProfileImage = ({ profileImage, getProfileRootProps, getProfileInputProps }) => (
  <div className="relative -mt-20 sm:-mt-24 flex justify-center">
    <div className="relative group">
      <img 
        src={profileImage || defaultProfileImage} 
        alt="Profile" 
        className="w-32 h-32 sm:w-40 sm:h-40 rounded-full border-4 border-white object-cover shadow-lg transition-all duration-300 ease-in-out"
      />
      <div {...getProfileRootProps()} className="absolute bottom-0 right-0 bg-white rounded-full p-2 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <input {...getProfileInputProps()} />
        <FaCamera className="text-purple-600" />
      </div>
    </div>
  </div>
);

const StatsSection = ({ stats, t }) => (
  <div className="flex justify-center space-x-8 sm:space-x-16 my-8">
    {stats.map((stat, index) => (
      <div key={index} className="text-center transition-all duration-300 ease-in-out hover:transform hover:scale-105">
        <div className="text-2xl sm:text-3xl font-bold text-gray-800">{stat.value}</div>
        <div className="text-sm sm:text-base text-gray-600">{t(stat.label)}</div>
      </div>
    ))}
  </div>
);

const TabsSection = ({ tabs, setNotifi, t }) => (
  <div className="flex justify-center px-4 mb-8">
    <div className="bg-white rounded-full p-1 sm:p-2 flex flex-wrap justify-center shadow-md">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className="py-2 px-4 rounded-full text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-200 hover:bg-purple-50"
          onClick={() => setNotifi(tab.id)}
        >
          {t(tab.label)}
        </button>
      ))}
    </div>
  </div>
);

export default EditProfileComponent;