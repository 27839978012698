import React from 'react'
import JoinTravel_officee from '../components/JoinTravel_officee'

function JoinTravel_officeePage() {
    return (
        <div className="">
            <JoinTravel_officee/>
        </div>
        
    )
}

export default JoinTravel_officeePage
