import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GoogleMap, LoadScript, Marker, } from '@react-google-maps/api';
const AboutOffice = ({bio,locatiom}) => {

  const {t} = useTranslation();
  const mapStyles = {
    height: "400px",
    width: "100%"
  };

    return (
    <div className="grid grid-cols-1 w-full h-auto mx-auto p-[80px] rounded-lg">
      <p className="text-2xl text-gray-800 text-right font-medium pr-12 space-y-1 tracking-wide leading-loose">
        {bio}

      </p>

      <div className="mt-8">
        <h3 className="text-center pl-12 text-lg font-bold">{t('des_property_address')}</h3>
        <div className="mt-4">
        <LoadScript googleMapsApiKey="AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={13}
              center={ locatiom }
            >
              <Marker position={ locatiom } />
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
      <div className="text-center mb-[-103px]">
        <Link to="">
          <button className="bg-[#FFA500] hover:bg-amber-500 text-[#fff] w-[220px] rounded-full font-medium my-6 md:py-4 text-[23px]">
             {t('des_proprty_now')}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AboutOffice;
