
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Image2 from '../../src/assets/ImagesVehicle/Profile.jfif';
// import '../../css/style2.css';
import { IoClose, IoAdd, IoMic, IoHappy } from 'react-icons/io5';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import { FaPaperPlane } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { TiAttachmentOutline } from "react-icons/ti";
import { useTranslation } from 'react-i18next';
import { useReactMediaRecorder } from "react-media-recorder";
import { FaStop } from "react-icons/fa";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // استيراد الدالة بشكل صحيح
import Pusher from 'pusher-js';
// import { useEffect, useState } from 'react';





// import Pusher from 'pusher-js';
// import axios from 'axios';

const Support = () => {
  // const {
  //   startRecording,
  //   stopRecording,
  //   mediaBlobUrl,
  // } = useReactMediaRecorder({ audio: true });
  const [isRecording, setIsRecording] = useState(false);
  const {
    startRecording,
    stopRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({ audio: true });

  const handleStartRecording = () => {
    setIsRecording(true);
    startRecording();
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    stopRecording();
  };

  const removeFile = (file) => () => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token); // فك تشفير الـ token
  const userId = decodedToken.sub; //

  console.log(userId)

  const [fileimg, setFiles] = useState([]);
  const [base64Files, setBase64Files] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*,video/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      setShowModal(true)
    },

  });


  // const token = localStorage.getItem('token');

  const [ChatMembers, setChatMembers] = useState([]);

  useEffect(() => {
    ChatMember();
  }, [])
  const ChatMember = async () => {
    await axios.get('https://nawyapp.com/api/ChatMember', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(({ data }) => {
      setChatMembers(data)

    }).then()
  }





  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // إعداد الاتصال مع Pusher 
    const pusher = new Pusher('0bd2ccc01118ca8a0230', {
      cluster: 'ap2',
      forceTLS: true
  });

    // الاشتراك في القناة التي تستخدمها في Laravel
    const channel = pusher.subscribe('GRP-2');

    // الاستماع إلى الحدث المحدد الذي ترسله من Laravel
    channel.bind('2', function (data) {
      console.log(data)
      // تحديث حالة الرسائل عند استلام رسالة جديدة
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    // تنظيف الاشتراك عند إلغاء تثبيت المكون
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);














  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [recordState, setRecordState] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const fileInputRef = React.useRef(null);
  const [showModal, setShowModal] = useState(false);



  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const { t } = useTranslation();

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  // const startRecording = () => {
  //   setRecordState(RecordState.START);
  // };

  // const stopRecording = () => {
  //   setRecordState(RecordState.STOP);
  // };

  const onStop = (audioData) => {
    const audioBlob = new Blob([audioData.blob], { type: 'audio/wav' });
    const audioFile = new File([audioBlob], 'recording.wav', {
      type: 'audio/wav',
    });
    setFiles((prevFiles) => [...prevFiles, audioFile]);
    setAudioFile(audioFile);
  };


  // const addEmoji = (event, emojiObject) => {

  //   setInputValue(prevInput => prevInput + emojiObject.emoji);  }


  const sendText = () => {
    console.log('Send:', inputValue);
    setInputValue('');
  }
  const [Messageapi, setMessageapi] = useState([]);


  // useEffect(() => {
  //   Pusher.logToConsole = true;

  //   const pusher = new Pusher('a7054637f68aecdc40aa', {
  //     cluster: 'ap2',
  //     encrypted: true
  //   });

  //   const channel = pusher.subscribe('nawi');
  //   channel.bind('message', (data) => {
  //     setMessages([...messages, data.message]);
  // });

  // return () => {
  // channel.unbind_all();
  // channel.unsubscribe();
  // };
  // }, [messages]);

  // const handleMessageChange = (e) => {
  //   setMessage(e.target.value);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   await axios.post('https://loclahost:8000/api/messages', { message });
  //   setMessage('');
  // };
  const showmessage = async (id) => {
    await axios.get('http://143.198.166.228/api/message/' + id,).then(({ data }) => {
      setMessageapi(data.messages)
      console.log(Messageapi)
    }).then()
  }

  const addmessage = async () => {
    const data={
      message_content:message,
      chat_full_id:2,
      file:null
    };
    await axios.post('http://143.198.166.228/api/Chat',data,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
  }
  return (
    <div className="min-h-screen bg-gray-200 flex flex-col items-center dir">
      <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row mt-10">
        {/* Sidebar */}
        <div className="w-full md:w-1/3 bg-white p-4">
          <div className="text-xl font-semibold mb-4">التطبيق</div>
          <input
            type="search"
            className="border-2 border-gray-200 rounded-lg w-full h-8 text-right p-5 pl-3  mb-4 focus:outline-none focus:border-purple-600"
            placeholder="ابحث عن محادثة"
          />
          <a href="#1">
            <svg
              className="mt-[-48px] ml-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#828282"
                stroke-width="2"
              />
              <path
                d="M21.0004 20.9999L16.6504 16.6499"
                stroke="#828282"
                stroke-width="2"
              />
            </svg>
          </a>
          <br></br>
          {ChatMembers.map((c) => (
            <div key={c.id} className="flex border-2 items-center " onClick={() => showmessage(c.chat_full_id)}>

              <img src={Image2} className='rounded-full w-16 h-16' alt="" srcset="" />
              <h1 className='p-3'>{c.chat_full.title}</h1>
              <br /> <br />
            </div>
          ))
          }


          {/* <div className="space-y-4">
            {files.map((file, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(file)}
                  alt="preview"
                  className="w-32 h-32 object-cover rounded-lg"
                />
                <button
                  onClick={() => handleDelete(index)}
                  className="absolute top-0 right-0 bg-orange-500 text-white rounded-full p-1"
                >
                  <IoClose />
                </button>
              </div>
            ))}
          </div>
          <div
            onClick={openFileDialog}
            className="cursor-pointer flex items-center justify-center w-32 h-32 bg-white text-gray-500 rounded-lg border border-gray-300"
          >
            <IoAdd size={24} />
          </div>
          <input
            type="file"
            accept="image/*,video/*"
            multiple
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileChange}
          /> */}
        </div>

        {/* Chat area */}
        <div className="w-full md:w-2/3 bg-white flex flex-col">
          <div className="border-b p-4 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <img
                src={Image2}
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div>
                <input
                  className="font-semibold bg-white"
                // value={username}
                // onChange={(e) => setUsername(e.target.value)}
                />
                <div className="text-sm text-gray-600">Active 20m ago</div>
              </div>
            </div>
          </div>



          {/* Chat messages */}
          <div className="flex-1 p-6 space-y-4 overflow-auto bg-[#ُEBEAFA]">
            {/* {
              Messageapi.map((c) => (
                c.sender_id == userId ?
                  <div className="flex justify-end">
                    <div className="bg-[#800080] text-white p-2 rounded-lg max-w-xs">
                      {c.message_content}
                    </div>
                  </div>
                  :
                  <div className="flex">
                    <div className="bg-[#f0f0f0] p-2 rounded-lg max-w-xs">{c.message_content}</div>
                  </div>
              ))
            } */}
            {messages.map((message, index) => (
              <li key={index}>{message.message_content}</li>
            ))}

          </div>

          {/* Message input */}

          <div className="flex items-center gap-2">
            {/* <AudioReactRecorder
              state={recordState}
              onStop={onStop}
              canvasWidth={0}
              canvasHeight={0}
            />
            <button
              onClick={startRecording}
              className="p-2 bg-red-500 text-white rounded-full"
            >
              <IoMic size={24} />
            </button>
            <button
              onClick={stopRecording}
              className="p-2 bg-red-500 text-white rounded-full"
            >
              إيقاف
            </button>
            <button
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="p-2 bg-yellow-500 text-white rounded-full"
            >
              <IoHappy size={24} />
            </button>
            {showEmojiPicker && (
              <div className="absolute z-10 mb-[550px]">
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )} */}
            <div className="chat-audio-recorder">
              {/* {!isRecording ? (
              <button onClick={startRecording} className="btn btn-record"> <IoMic size={24} /> </button>) :(
              <button onClick={stopRecording} className="btn btn-stop"> <FaStop /></button>
              )} */}
              {!isRecording ? (
                <button onClick={handleStartRecording} className="btn btn-record"> <IoMic size={24} /> </button>
              ) : (
                <button onClick={handleStopRecording} className="btn btn-stop"> <FaStop /></button>
              )}
              <button
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="p-2 bg-yellow-500 text-white rounded-full"
              >
                <IoHappy size={24} />
              </button>
              {showEmojiPicker && (
                <div className="absolute z-10 mb-[550px]">
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              )}
              {mediaBlobUrl && (
                <div>
                  <audio src={mediaBlobUrl} controls className="audio-player" />
                </div>
              )}
            </div>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="flex-1 p-2 border border-gray-300 rounded-lg"
              placeholder="اكتب رسالة..."
            />
            <button onClick={addmessage} className='bg-blue-500 text-white p-1 rounded flex items-center'>
              <FaPaperPlane className='mr-2' />
            </button>
            <div className="mt-4 grid grid-cols-3 gap-3">

              <div
                {...getRootProps({ className: "dropzone" })}
                className="flex items-center justify-center rounded-2xl bg-white  cursor-pointer"
              >
                <input {...getInputProps()} />
                <span className="text-3xl" >
                  <TiAttachmentOutline />

                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 bg-white p-4">
          <div className="space-y-4">
            <div className="flex items-center justify-center">
              <img
                src={Image2}
                alt="Profile"
                className="w-[55%] h-[55%] rounded-full"
              />
            </div>
            <div className="font-semibold text-xl text-center">لانا محمد</div>
            <Link to="#4">
              <button className="bg-[#800080] ml-[30px] hover:bg-amber-500 text-[#fff] w-[180px] rounded-full font-medium my-6 py-3 text-[18px]">
                الملف الشخصي
              </button>
            </Link>
          </div>
        </div>
      </div>
      {showModal && (


        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">

              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
                  {t('profile_send')}
                </h3>

                <div className="flex justify-center">
                  <div className="mt-4 grid grid-cols-3 gap-3 p-4">
                    {fileimg.map((file, index) => (
                      <div key={index} className="relative">

                        <div className="absolute top-0 right-20">
                          <button
                            type="button"
                            onClick={removeFile(file)}
                            className="bg-green-600 text-white rounded-full p-1 w-6 h-8  hover:bg-red-700"
                          >
                            x
                          </button>
                        </div>
                        {file.type.startsWith("image") ? (

                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-36 h-36 object-cover rounded"
                          />
                        ) : (
                          <video
                            controls
                            src={URL.createObjectURL(file)}
                            className="w-36 h-36 object-cover rounded"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                // onClick={() => setShowModal(false)}
                >
                  {t('profile_send')}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                  onClick={() => setShowModal(false)}
                >
                  {t('profile_close')}
                </button>
              </div>

            </div>


          </div>


        </div>
      )}
    </div>
  );
};

export default Support;

