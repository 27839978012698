import React, { useEffect, useState } from "react";
import Laptop from "../assets/Images/nawi.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { motion } from "framer-motion";

const Hero = () => {
  const { t } = useTranslation();
  const [count, setCount] = useState([]);

  useEffect(() => {
    fetchCategoriesProperties();
  }, []);

  const fetchCategoriesProperties = async () => {
    await axios.get('https://nawyapp.com/api/categories_properties/create').then(({ data }) => {
      setCount(data);
    });
  };

  const stats = [
    { title: 'hero_users_count', count: count['users_count'], description: 'hero_services' },
    { title: 'hero_properties', count: count['Property_count'], description: 'hero_houses', titleColor: 'text-[#1c0080]' },
    { title: 'hero_travel', count: count['order_travel_count'], description: 'hero_moretravel' },
    { title: 'hero_vehicle', count: count['vehicle_count'], description: 'hero_moretravel' }
  ];

  return (
    <div className="w-full py-12 px-4 sm:px-6 lg:px-8 bg-purple-200">
      <div className="max-w-7xl mx-auto">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-6"
          >
            <h1 className="text-3xl sm:text-4xl font-bold">
              {t('hero_header')}{' '}
              <span className="text-[#800080]">{t('hero_header2')}</span>
            </h1>
            <p className="text-xl sm:text-2xl">{t('hero_header3')}</p>
            <Link to="/choose_categories">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-[#FFA500] hover:bg-amber-500 text-white w-full sm:w-auto px-8 py-3 rounded-full font-medium text-xl sm:text-2xl transition duration-300"
              >
                {t('hero_let_go')}
              </motion.button>
            </Link>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="flex justify-center"
          >
            <img className="w-full max-w-md h-auto" src={Laptop} alt="Hero" />
          </motion.div>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>

        <div className="mt-16 text-center sm:text-left">
          <p className="text-2xl font-bold flex flex-col sm:flex-row items-center justify-center sm:justify-start">
            <Link to="/choose_categories">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gray-600 hover:bg-amber-500 text-white px-6 py-2 rounded-md font-normal text-xl transition duration-300 mb-4 sm:mb-0 sm:mr-4"
              >
                {t('hero_serv')}
              </motion.button>
            </Link>
            <span>{t('hero_new_way')}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ title, description, count, titleColor = 'text-[#800080]' }) => {
  const { t } = useTranslation();
  const [displayCount, setDisplayCount] = useState(0);

  useEffect(() => {
    const startCount = 0;
    const endCount = count;
    const duration = 2000; // Animation duration in milliseconds
    const incrementAmount = Math.ceil((endCount - startCount) / (duration / 50));

    let currentCount = startCount;
    const timer = setInterval(() => {
      currentCount += incrementAmount;
      if (currentCount >= endCount) {
        currentCount = endCount;
        clearInterval(timer);
      }
      setDisplayCount(currentCount);
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
      className="flex flex-col items-center text-center"
    >
      <motion.img
        whileHover={{ scale: 1.1 }}
        src={Laptop}
        alt={title}
        className="w-16 h-12 mb-4"
      />
      <h3 className={`text-lg font-medium ${titleColor}`}>
        {t(title)} {displayCount}
      </h3>
      <p className="text-base text-gray-600 mt-1">{t(description)}</p>
    </motion.div>
  );
};

export default Hero;