import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import nawi from "../assets/Images/nawi.svg";
import { Link } from "react-router-dom";
import imgscr from '../assets/ImagesVehicle/person.png'

function Navbarlogin() {
    const [nav, setNav] = useState(false);
    const sidebarRef = useRef(null);

    const handleNav = () => {
      setNav(!nav);
    };

    const closeNav = () => {
      setNav(false);
    };

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target) && nav) {
          closeNav();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [nav]);

    const navItems = [
      { label: "الدعم", path: "/support" },
      { label: "اهتمامي", path: "/interests" },
      { label: "بيع", path: "/vehicle/add1" },
      { label: "ملفي الشخصي", path: "/Profile" },
      { label: "طلباتي", path: "/Order" },
      { label: "الخريطة", path: "/map" },
      { label: "الرئيسية", path: "/" },
    ];

    return (
        <div className="flex justify-between items-center h-16 max-w-full mx-auto text-white bg-[#ffffff] relative">
          <img
            className="flex justify-between items-start h-16 max-w-full mx-auto"
            src={nawi}
            alt="/"
          />
    
          <ul className="hidden lg:flex mx-60">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link to={item.path}>
                  <button className="p-3 mt-2 mr-4 rounded-full text-black focus:bg-[#800080] focus:text-white hover:bg-[#800080] hover:text-white transition-colors duration-300">
                    {item.label}
                  </button>
                </Link>
              </li>
            ))}
            <div className="flex justify-between items-center h-16 max-w-[1240px] text-white bg-[#fff] mr-4">
              <Link>
                <li className="py-0 mx-12 mt-2 text-black">
                  <div className="flex items-center">
                    <img src={imgscr} width="50px" height="40px" className="rounded-full" alt="" />
                    <p className="py-3 px-3">louay@gmail.com</p>
                  </div>
                </li>
              </Link>
            </div>
          </ul>

          <div onClick={handleNav} className="block lg:hidden text-[#800080] cursor-pointer z-50">
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>

          {nav && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={closeNav}></div>
          )}

          <div ref={sidebarRef} className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#800080] ease-in-out duration-500 z-40"
              : "fixed left-[-100%] top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#800080] ease-in-out duration-500 z-40"
          }>
            <img
              className="flex justify-between items-start h-16 max-w-full mx-auto"
              src={nawi}
              alt="/"
            />
            <ul className="pt-12">
              {navItems.map((item, index) => (
                <Link key={index} to={item.path} onClick={closeNav}>
                  <li className="p-4 border-b border-gray-500 hover:text-[#FFA500] cursor-pointer">
                    {item.label}
                  </li>
                </Link>
              ))}
              <li className="p-4 border-b border-gray-500">
                <div className="flex items-center">
                  <img src={imgscr} width="40px" height="40px" className="rounded-full" alt="" />
                  <p className="py-3 px-3 text-white">louay@gmail.com</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
    )
}

export default Navbarlogin