import React, { useCallback, useRef, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 24.7136, // المركز الافتراضي للخريطة
  lng: 46.6753
};

function MapComponent({ onLocationSelect }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY' // تأكد من إضافة مفتاح API في .env
  });

  const [markerPosition, setMarkerPosition] = useState(center);
  const mapRef = useRef(null);

  const onMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    onLocationSelect({ lat, lng });
    console.log(lat,lng);
  }, [onLocationSelect]);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onClick={onMapClick}
      onLoad={onLoad}
    >
      <Marker position={markerPosition} />
    </GoogleMap>
  ) : <></>;
}

export default React.memo(MapComponent);