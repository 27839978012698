import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Add1 = ({ updateFormData }) => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState(null);
  const { t } = useTranslation();

  const handleNext = () => {
    navigate("/vehicle/add2");
  };

  return (
    <div className="bg-[#EBEAFA] min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h2 className="text-3xl font-semibold text-center mb-8">
          {t("add1_vehicle_wel")} <span>{t("add1_vehicle_name")}</span>
          {t("add1_vehicle_fill")}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="mb-6">
            <label htmlFor="status_car" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_state")}
            </label>
            <div className="relative">
              <select
                id="status_car"
                name="status_car"
                onChange={updateFormData}
                className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="مستعملة">{t("add1_vehicle_used")}</option>
                <option value="جديدة">{t("add1_vehicle_new")}</option>
              </select>
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="vechicle_make_id" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_type")}
            </label>
            <div className="relative">
              <select
                id="vechicle_make_id"
                onChange={updateFormData}
                className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="مارسيدس">{t("add1_vehicle_benz")}</option>
                <option value="مارسيدس">{t("add1_vehicle_benz")}</option>
              </select>
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="vechicle_model_id" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_model")}
            </label>
            <div className="relative">
              <select
                id="vechicle_model_id"
                onChange={updateFormData}
                className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="بنز">{t("add1_vehicle_model2")}</option>
                <option value="بنز">{t("add1_vehicle_model2")}</option>
              </select>
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="Vehicle_class" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_class")}
            </label>
            <div className="relative">
              <select
                id="Vehicle_class"
                name="Vehicle_class"
                onChange={updateFormData}
                className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="s432">s432</option>
                <option value="g3">g3</option>
              </select>
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="year" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_year")}
            </label>
            <input
              type="text"
              id="year"
              name="year"
              onChange={updateFormData}
              className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="mileage" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_mileage")}
            </label>
            <input
              type="text"
              id="mileage"
              name="mileage"
              onChange={updateFormData}
              placeholder="40 000"
              className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="number_of_seats" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_num")}
            </label>
            <input
              type="number"
              id="number_of_seats"
              name="number_of_seats"
              onChange={updateFormData}
              className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="fuel_type" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_fuel")}
            </label>
            <div className="relative">
              <select
                id="fuel_type"
                name="fuel_type"
                onChange={updateFormData}
                className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="m">{t("add1_vehicle_fuel1")}</option>
                <option value="n">{t("add1_vehicle_fuel2")}</option>
                <option value="n">{t("add1_vehicle_fuel3")}</option>
              </select>
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="Motion_vector" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_trans")}
            </label>
            <div className="relative">
              <select
                id="Motion_vector"
                name="Motion_vector"
                onChange={updateFormData}
                className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              >
                <option value="أوتوماتيكي">{t("add1_vehicle_trans1")}</option>
                <option value="عادي">{t("add1_vehicle_trans2")}</option>
              </select>
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="horsepower" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_num_horse")}
            </label>
            <input
              type="number"
              id="horsepower"
              name="horsepower"
              onChange={updateFormData}
              className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="engine_size" className="block text-xl font-semibold mb-2">
              {t("add1_vehicle_engine")}
            </label>
            <input
              type="number"
              id="engine_size"
              name="engine_size"
              onChange={updateFormData}
              className="block w-full px-4 py-3 text-right text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
          </div>
        </div>

        <div className="flex justify-between items-center mt-8">
          <Link to="">
            <div className="bg-white rounded-full w-10 h-10 p-3 flex justify-center items-center hover:bg-[#FFA500]">
              <svg
                width="15"
                height="20"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998"
                  stroke="#000"
                  strokeWidth="1.6"
                />
              </svg>
            </div>
          </Link>
          <span className="font-medium">1/6</span>
          <button
            onClick={handleNext}
            type="button"
            className="bg-[#FFA500] hover:bg-amber-500 text-white rounded-full font-medium px-8 py-3 text-lg transition duration-300"
          >
            {t("add1_vehicle_next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add1;