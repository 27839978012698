import React, { useEffect, useState } from 'react'
import BackgroundB from '../Property/BackgroundInf';
import AboutBroker from './AboutBroker';
import DescriptionBroker from './DescriptionBroker';
import AllOrderBroker from './AllOrderBroker';
import axios from 'axios';


const BrokerServices = ({ props }) => {
  const [Des3, setDes3] = useState(0);

  const [username, setusername] = useState('')
  const [description, setdescription] = useState('')
  const [country, setcountry] = useState('')
  const [city, setcity] = useState('')
  const [id, setid] = useState('')
  const [imageName, setimage] = useState('')
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const imageUrl = `https://nawyapp.com/storage/${imageName}`;
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/Broker/1',
    ).then(({ data }) => {
      setusername(data.user.username)
      setdescription(data.description)
      setDefaultCenter({
        lat: data.user.location.latitud,
        lng: data.user.location.longitude
      })
      setcountry(data.user.location.country)
      setcity(data.user.location.city)
      setid(data.id)
      setimage(data.image)

    });
  }
  useEffect(() => {
    fetchCategories();
  }, [])


  return (

    <div className='bg-[#EBEAFA]'>
     <BackgroundB type={'Broker'} username={username} imageUrl={imageUrl} country={country} city={city} id={id} />
      <DescriptionBroker setDes={setDes3} />
      {Des3 === 0 && <AboutBroker description={description} center={defaultCenter} />}
      {Des3 === 1 && <AllOrderBroker />}
      <br></br>
    </div>
  )
}

export default BrokerServices