import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

const Add3 = ({ updateFormData, updatecheckbox, formData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Handle checkbox states dynamically
  const [checkedState, setCheckedState] = useState({
    Fog_lights: false,
    Spare_tire: false,
    electric_mirrors: false,
    tinted_windows: false,
    panorama: false,
    lights: false,
    original_color: false,
  });

  // Dynamic checkbox handler
  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    setCheckedState({
      ...checkedState,
      [name]: !checkedState[name],
    });
    updatecheckbox(e);
  };

  const handleNext = () => {
    navigate('/vehicle/add4');
  };

  return (
    <div className="bg-[#EBEAFA] min-h-screen flex items-center justify-center">
      <div className="p-8 bg-white rounded-2xl shadow-lg max-w-3xl w-full">
        <h2 className="text-3xl font-semibold text-center mb-6">
          {t('add1_vehicle_wel')} <span>{t('add1_vehicle_name')}</span>{t('add1_vehicle_fill')}
        </h2>

        <h3 className="text-2xl font-black text-right mb-8">{t('add3_vehicle_exter')}</h3>

        {/* Exterior Color */}
        <div className="mb-6">
          <label className="block text-xl font-semibold mb-2">{t('add3_vehicle_color')}</label>
          <input
            type="color"
            className="w-full h-16 rounded-full"
            name="exterior_color"
            onChange={updateFormData}
          />
        </div>

        {/* Chassis Type */}
        <div className="mb-6">
          <label className="block text-xl font-semibold mb-2">{t('add2_vehicle_chassi')}</label>
          <input
            type="text"
            className="w-full border text-black rounded-lg p-4 text-right focus:border-purple-800 transition-colors"
            name="structure_type"
            onChange={updateFormData}
          />
        </div>

        {/* Accident State */}
        <div className="mb-6">
          <label className="block text-xl font-semibold mb-2">{t('add3_vehicle_state')}</label>
          <input
            type="text"
            className="w-full border text-black rounded-lg p-4 text-right focus:border-purple-800 transition-colors"
            name="has_been_in_accidents"
            placeholder="هل تعرضت لحادث؟"
            onChange={updateFormData}
          />
        </div>

        {/* Features */}
        <h3 className="text-2xl font-black text-right mb-6">{t('add3_vehicle_featu')}</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {[
            { name: 'Fog_lights', label: t('add3_vehicle_lamp') },
            { name: 'Spare_tire', label: t('add3_vehicle_tire') },
            { name: 'electric_mirrors', label: t('add3_vehicle_mirr') },
            { name: 'tinted_windows', label: t('add3_vehicle_windw') },
            { name: 'panorama', label: t('add3_vehicle_panor') },
            { name: 'lights', label: t('add3_vehicle_light') },
            { name: 'original_color', label: t('add3_vehicle_orig') },
          ].map((item, index) => (
            <label key={index} className="flex items-center space-x-3">
              <input
                type="checkbox"
                className="hidden"
                name={item.name}
                checked={checkedState[item.name]}
                onChange={handleCheckboxChange}
              />
              <span
                className={`inline-block w-5 h-5 border-2 rounded ${checkedState[item.name]
                  ? "bg-green-500 text-white border-green-500"
                  : "border-gray-600"
                  } flex items-center justify-center`}
              >
                {checkedState[item.name] && (
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
              </span>
              <span className="text-lg font-normal text-gray-800">{item.label}</span>
            </label>
          ))}
        </div>

        {/* Navigation */}
        <div className="flex justify-between mt-10">
          <Link to="/vehicle/add2">
            <button className="bg-white border-2 border-purple-500 text-purple-500 p-2 rounded-full hover:bg-purple-500 hover:text-white">
              <svg
                width="15"
                height="20"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998"
                  stroke="#000"
                  strokeWidth="1.6"
                />
              </svg>
            </button>
          </Link>

          <button
            onClick={handleNext}
            className="bg-purple-600 hover:bg-purple-700 text-white rounded-full px-8 py-3"
          >
            {t('add1_vehicle_next')}
          </button>
        </div>
        <h3 className="text-center mt-4">{`3/6`}</h3>
      </div>
    </div>
  );
};

export default Add3;
