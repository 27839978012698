import React, { useEffect, useState } from "react";
import DescriptionVehicle from "./DescriptionVehicle";
import DescriptionCard from "./DescriptionCard";
import VehicleList from "./VehicleList";
import CarouselVehicle from "./CarouselVehicle";
import DescriptionCarouselVehicle from "./DescriptionCarouselVehicle";
import ImagesvVehicle from "./ImagesvVehicle";
import VideosVehicle from "./VideosVehicle";
import { useParams } from "react-router-dom";
import axios from "axios";

const DetailsCardsVehicle = () => {
  const [DesCard, setDesCard] = useState(0);
  const { id } = useParams(); 
  const [loading, setLoading] = useState(true);
  const [car,setcar] = useState([]);
  const [images,setimages] = useState([]);
  const fetchproperty = async () => {
    await axios.get('https://nawyapp.com/api/showVehicle/' + 4).then(({ data }) => {
      setcar(data)
      fetchimages(data.id);
      
    });
  }
  useEffect(() => {
    fetchproperty();
 
  }, [])
  const fetchimages = async (id) => {
      
    await axios.get(`https://nawyapp.com/api/showfiles`, {
      params: {
        type: 'property',
        id: id
      }
    } ).then(({ data }) => {
      setimages(data)
      console.log(data)
      setLoading(false);
    });
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-[#EBEAFA]">
      <CarouselVehicle images={images} make={car.vechicle_make.name} vId={car.id} />
      <DescriptionCarouselVehicle  make={car.vechicle_make.name} model={ car.vechicle_model.name} />
      <DescriptionCard setDesCard={setDesCard} />
        {DesCard === 0 && <DescriptionVehicle car ={car}  />}
        {DesCard === 1 && <ImagesvVehicle images={images} />}
        {/* {DesCard === 1 && <VideosVehicle />} */}
      <div className="min-h-screen">
        {DesCard === 2 && <VehicleList vId={car.id} />}
      </div>
    </div>
  );
};

export default DetailsCardsVehicle;
