import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from 'framer-motion';
import pana from "../assets/Images/pana.png";

const Verificationpayment = () => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(60);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const timerId = seconds > 0 && setInterval(() => setSeconds(s => s - 1), 1000);
    return () => clearInterval(timerId);
  }, [seconds]);

  const handleResend = () => {
    if (seconds === 0) {
      setSeconds(60);
      setError('');
      // Add your resend logic here
      alert(t('Verif_code_resent'));
    }
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (otp.join('').length !== 4) {
      setError(t('please_enter_code'));
      setIsSubmitting(false);
    } else {
      // Simulate API call
      setTimeout(() => {
        setIsSubmitting(false);
        // Navigate to next page or handle successful verification
      }, 2000);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="p-6 sm:p-8">
          <motion.div 
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex justify-center mb-6"
          >
            <img src={pana} alt="Payment Illustration" className="w-48 sm:w-64" />
          </motion.div>
          
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4">
            {t('Verif_title')}
          </h1>
          
          <p className="text-center text-gray-600 mb-8">
            {t("Verif_body")}
          </p>
          
          <div className="mb-8">
            <div className="flex justify-between mb-2">
              {otp.map((data, index) => {
                return (
                  <input
                    className="w-16 h-16 text-center text-xl font-bold border-2 border-gray-300 rounded-lg focus:border-purple-500 focus:outline-none transition-colors"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={e => handleChange(e.target, index)}
                    onFocus={e => e.target.select()}
                  />
                );
              })}
            </div>
            <AnimatePresence>
              {error && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="text-red-500 text-sm mt-2"
                >
                  {error}
                </motion.p>
              )}
            </AnimatePresence>
          </div>
          
          <div className="flex items-center justify-center mb-6">
            <div className="bg-purple-100 rounded-full p-3 flex items-center">
              <svg className="w-5 h-5 text-purple-600 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span className="font-semibold text-purple-600">{seconds}s</span>
            </div>
          </div>
          
          <div className="text-center mb-8">
            <span className="text-gray-600">{t('Verif_receive')}</span>
            <button
              className={`ml-2 font-semibold ${seconds > 0 ? 'text-gray-400 cursor-not-allowed' : 'text-purple-600 hover:text-purple-800'}`}
              disabled={seconds > 0}
              onClick={handleResend}
            >
              {t('Verif_Re_transmitter')}
            </button>
          </div>
          
          <div className="flex justify-between items-center">
            <Link to="/Payment" className="bg-white text-purple-600 p-2 rounded-full shadow hover:bg-purple-100 transition-colors">
              <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M15 19l-7-7 7-7"></path>
              </svg>
            </Link>
            
            <motion.button
              whileTap={{ scale: 0.95 }}
              className={`bg-yellow-500 text-white font-bold py-3 px-6 rounded-full transition-colors ${isSubmitting ? 'opacity-75 cursor-not-allowed' : 'hover:bg-yellow-600'}`}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : t('payment_button')}
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verificationpayment;