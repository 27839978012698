import React from 'react'
import OrderComponents from '../components/OrderComponents'
import Search from '../components/Homepage/search'
import SidebarC from '../components/SidebarC'

function Order() {
    return (
        <div className="bg-[#ebeafa] min-h-screen">
            <SidebarC />
            <div className="p-4 pt-32"> {/* Increased top padding to accommodate the floating navbar */}
                <Search />
                <OrderComponents />
            </div>
        </div>
    )
}

export default Order