import React from 'react'
import JionInfluencer from '../components/JionInfluencer'

function JionInfluencerpage() {
    return (
        <div>
            <JionInfluencer/>
        </div>
        
    )
}

export default JionInfluencer
