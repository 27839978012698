import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Img from '../assets/Images/Flat1.png';
import '../assets/Css/style.css';

const DetailsProperty = () => {
  const { t } = useTranslation();

  const sections = [
    { title: 'details_ad', content: <PropertyOverview /> },
    { title: 'details_Transfer', content: <TransferDetails /> },
    { title: 'details_Payment', content: <PaymentDetails /> },
    { title: 'details_method', content: <PaymentMethod /> },
  ];

  return (
    <div className="bg-[#ebeafa] min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto space-y-8">
        {sections.map((section, index) => (
          <Section key={index} title={t(section.title)}>
            {section.content}
          </Section>
        ))}
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <section className="space-y-4">
    <h2 className="text-2xl font-bold text-gray-800 text-right">{title}</h2>
    {children}
  </section>
);

const PropertyOverview = () => {
  const { t } = useTranslation();
  
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-4 sm:p-6 flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 sm:mb-0">
          <img src={Img} alt="" className="w-32 h-auto mb-4 sm:mb-0 sm:mr-4" />
          <div>
            <p className="bg-green-200 text-green-700 px-2 py-1 rounded-full text-sm font-medium inline-block mb-2">
              {t('details_Licensed')}
            </p>
            <p className="text-blue-800 text-xl font-bold">3000 Rial</p>
          </div>
        </div>
        <div className="text-right">
          <h3 className="text-xl font-semibold mb-2">{t('details_name')}</h3>
          <p className="text-gray-600 mb-2">{t('details_location')}</p>
          <div className="flex justify-end space-x-4 text-sm text-gray-500">
            <p>5x7</p>
            <p>{t('details_bathroom', { count: 9 })}</p>
            <p>{t('details_rooms', { count: 3 })}</p>
          </div>
        </div>
      </div>
      <Link to="" className="block bg-gray-50 text-center py-2 text-indigo-600 hover:text-indigo-800 transition duration-150 ease-in-out">
        {t('details_details')}
      </Link>
    </div>
  );
};

const TransferDetails = () => {
  const { t } = useTranslation();
  
  const details = [
    { label: 'details_chin', value: '11/28/2921' },
    { label: 'details_chout', value: '01/28/2022' },
    { label: 'details_owner', value: 'Louay' },
    { label: 'details_type', value: 'Rent' },
  ];

  return (
    <div className="bg-white rounded-lg overflow-hidden">
      <div className="grid grid-cols-2 gap-4 p-4 sm:p-6">
        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <p className="text-right font-medium">{t(detail.label)}</p>
            <p className="text-left">{detail.value}</p>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const PaymentDetails = () => {
  const { t } = useTranslation();
  
  const details = [
    { label: 'details_Paymenttime', value: '11/28/2921' },
    { label: 'details_Price', value: '220' },
    { label: 'details_Discount', value: '-10' },
    { label: 'details_Total', value: '210', highlight: true },
  ];

  return (
    <div className="bg-white rounded-lg overflow-hidden">
      <div className="grid grid-cols-2 gap-4 p-4 sm:p-6">
        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <p className="text-right font-medium">{t(detail.label)}</p>
            <p className={`text-left ${detail.highlight ? 'bg-[#ebeafa] p-2 rounded font-bold' : ''}`}>
              {detail.value}
            </p>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const PaymentMethod = () => (
  <div className="bg-white rounded-lg p-4 sm:p-6 text-center">
    <p className="text-lg">**********@gmail.com</p>
  </div>
);

export default DetailsProperty;