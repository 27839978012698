import React, { useState } from 'react'
import Header from '../components/header'
import SortComponents from '../components/SortComponents'
import SortvehicleComponents from '../components/SortvehicleComponents'
import SortTravelofficeeComponents from '../components/SortTravelofficeeComponents'
import { useTranslation } from "react-i18next";

function Sort() {
    const { t } = useTranslation();
    const [Notifi, setNotifi] = useState(3);

    return (
        <div className="">
            <Header title={t('sort_Archives')} />
            <div className="flex justify-center p-3 bg-[#ebeafa]">
                <button onClick={() => setNotifi(3)}  type="button" className='border-2 p-5 rounded-xl bg-white focus:bg-[#800080] focus:text-white'>{t('sort_Travel')}</button>
                <button onClick={() => setNotifi(2)} type="button" className='border-2 p-5 rounded-xl bg-white focus:bg-[#800080] focus:text-white'>{t('sort_propety')}</button>
                <button onClick={() => setNotifi(1)}  type="button" className='border-2 p-5 rounded-xl bg-white focus:bg-[#800080]  focus:text-white'>{t('sort_car')}</button>
            </div>
            {Notifi === 2 && <SortComponents/>}
            {Notifi === 1 && <SortvehicleComponents/>}
            {Notifi === 3 && <SortTravelofficeeComponents/>}
            {/* <SortComponents /> */}

        </div>
    )
}

export default Sort
