import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import vector from '../assets/ImagesVehicle/person.png';

const NotificationItem = ({ name, message }) => (
  <div className="bg-white rounded-xl shadow-md p-4 mb-4 transition-all duration-300 hover:shadow-lg">
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3 rtl:space-x-reverse">
        <img className="w-12 h-12 rounded-full" src={vector} alt={name} />
        <div>
          <h3 className="font-bold text-lg">{name}</h3>
          <p className="text-gray-600">{message}</p>
        </div>
      </div>
      <span className="text-sm text-gray-500">2h ago</span>
    </div>
  </div>
);

const Notificationsall = () => {
  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState('all');

  const filters = [
    { key: 'all', label: t('sort_all') },
    { key: 'car', label: t('sort_car') },
    { key: 'property', label: t('sort_propety') },
    { key: 'travel', label: t('sort_Travel') },
  ];

  const notifications = [
    { id: 1, name: 'محمد خليل', message: 'New car listing added', type: 'car' },
    { id: 2, name: 'أحمد علي', message: 'Property inquiry received', type: 'property' },
    { id: 3, name: 'فاطمة حسن', message: 'Travel package booked', type: 'travel' },
    { id: 4, name: 'عمر محمود', message: 'Car rental confirmed', type: 'car' },
    { id: 5, name: 'زينب سعيد', message: 'New property review', type: 'property' },
  ];

  const filteredNotifications = activeFilter === 'all' 
    ? notifications 
    : notifications.filter(notification => notification.type === activeFilter);

  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">{t('notifications')}</h1>
        
        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {filters.map((filter) => (
            <button
              key={filter.key}
              onClick={() => setActiveFilter(filter.key)}
              className={`px-4 py-2 rounded-full transition-colors duration-300 ${
                activeFilter === filter.key
                  ? 'bg-purple-600 text-white'
                  : 'bg-white text-gray-700 hover:bg-purple-100'
              }`}
            >
              {filter.label}
            </button>
          ))}
        </div>

        <div className="space-y-4">
          {filteredNotifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              name={notification.name}
              message={notification.message}
            />
          ))}
        </div>

        {filteredNotifications.length === 0 && (
          <p className="text-center text-gray-500 mt-8">
            {t('no_notifications')}
          </p>
        )}
      </div>
    </div>
  );
};

export default Notificationsall;