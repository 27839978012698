import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import pana from "../assets/Images/pana.png";
import "../assets/Css/style.css";

function PaymentComponent() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-[#ebeafa] right-to-left">
      <main className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-8">
            <div className="flex justify-center mb-8">
              <img src={pana} alt="Payment Illustration" className="w-full max-w-md h-auto" />
            </div>
            <h1 className='text-3xl font-bold text-center mb-8'>{t('payment_header')}</h1>
            <form className="space-y-6">
              <div className="space-y-4">
                <FormField
                  label={t('payment_name_owner')}
                  type="text"
                  icon={<UserIcon />}
                />
                <FormField
                  label={t('payment_numbercord')}
                  type="text"
                  icon={<CreditCardIcon />}
                />
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full sm:w-1/2 px-2 mb-4 sm:mb-0">
                    <FormField
                      label={t('payment_secret_code')}
                      type="text"
                      className="w-full"
                      icon={<LockIcon />}
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-2">
                    <FormField
                      label={t('payment_expiry_date')}
                      type="date"
                      className="w-full"
                      icon={<CalendarIcon />}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <Link to="/verifica_tionpaymen">
                  <button className="w-full py-3 bg-yellow-500 text-white font-bold rounded-full transition duration-300 ease-in-out hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50">
                    {t('payment_button')}
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}

const FormField = ({ label, type, className = "", icon }) => (
  <div className={`relative ${className}`}>
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <div className="relative">
      <input
        type={type}
        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        {icon}
      </div>
    </div>
  </div>
);

const UserIcon = () => (
  <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
  </svg>
);

const CreditCardIcon = () => (
  <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
  </svg>
);

const LockIcon = () => (
  <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
  </svg>
);

const CalendarIcon = () => (
  <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
  </svg>
);

export default PaymentComponent;