import React from 'react'
import JoinBrokerComponents from '../components/JoinBrokerComponents'

function JoinBroker() {
    return (
        <div>
            <JoinBrokerComponents/>
        </div>
    )
}

export default JoinBroker
