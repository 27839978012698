import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";

const Add6 = ({ formData, updateFormData }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const [fileimg, setFiles] = useState([]);
  const [base64Files, setBase64Files] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*,video/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  const convertFilesToBase64 = async (files) => {
    const promises = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({ base64: reader.result, type: file.type });
        reader.onerror = error => reject(error);
      });
    });
    try {
      const base64Files = await Promise.all(promises);
      setBase64Files(base64Files);
      return base64Files;
    } catch (error) {
      console.error('Error converting files to Base64:', error);
      throw error;
    }
  };

  const handleSubmitFile = async (response) => {
    try {
      const base64Files = await convertFilesToBase64(fileimg);
      await axios.post('https://nawyapp.com/api/files/', {
        image: base64Files,
        fileable_id: response,
        fileable_type: 'Vehicle',
      });
      console.log('Upload successful:', base64Files);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post('https://nawyapp.com/api/vehicle', formData, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      await handleSubmitFile(data);
      navigate('/share');
    } catch (error) {
      console.error('Error submitting vehicle:', error);
    }
  };

  const removeFile = (file) => () => {

    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    
  };

  return (
    <div className="bg-[#EBEAFA] min-h-screen flex items-center justify-center">
      <div className="p-8 bg-white rounded-lg shadow-md max-w-3xl w-full">
        <h2 className="text-3xl font-semibold text-center mb-6">
          {t('add6_vehicle_end')}
        </h2>

        {/* File Upload Section */}
        <div className="mb-8">
          <label className="text-xl block mb-4 font-semibold text-right">
            {t('add6_vehicle_add_img')}
          </label>
          <div className="grid grid-cols-3 gap-3">
            {fileimg.map((file, index) => (
              <div key={index} className="relative">
                <button
                  onClick={removeFile(file)}
                  className="absolute top-0 right-0 bg-red-600 text-white rounded-full p-1 hover:bg-red-700"
                >
                  x
                </button>
                {file.type.startsWith("image") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className="w-36 h-36 object-cover rounded"
                  />
                ) : (
                  <video
                    controls
                    src={URL.createObjectURL(file)}
                    className="w-36 h-36 object-cover rounded"
                  />
                )}
              </div>
            ))}
            <div
              {...getRootProps({ className: "dropzone" })}
              className="flex items-center justify-center w-36 h-36 rounded-2xl bg-white border-dashed border-2 cursor-pointer"
            >
              <input {...getInputProps()} />
              <span className="text-3xl">+</span>
            </div>
          </div>
        </div>

        {/* Description Section */}
        <div className="mb-8">
          <label className="text-xl block mb-4 font-semibold text-right">
            {t('add6_vehicle_regional')}
          </label>
          <textarea
            name="description"
            onChange={updateFormData}
            className="text-right w-full p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-purple-800 transition-colors"
            placeholder={t('add6_vehicle_input')}
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className="bg-[#FFA500] hover:bg-amber-500 text-white w-full rounded-full py-3 text-lg font-semibold transition duration-300"
        >
          {t('add1_vehicle_next')}
        </button>

        {/* Back Button */}
        <Link to="/vehicle/add5/">
          <div className="mt-6 bg-white rounded-full w-10 h-10 p-3 flex justify-center items-center shadow-md hover:bg-[#FFA500] transition duration-300">
            <svg
              width="15"
              height="20"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998" stroke="#000" strokeWidth="1.6" />
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Add6;
